import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CompaniesFilters from '../../companiesList/filters/companiesFilters';

import { getThemeColor } from '../../../../utils/theme';
import { PrimaryButtonS, Text } from '../../common';
import { StyledModal } from '../modalCommonS';

const FiltersModalS = styled(StyledModal)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  height: 30rem;
  padding: 0;
  border-radius: 3px;
`;

const ContentWrapS = styled.div`
  width: 100%;
  padding: 15px;
  
  overflow-y: auto;
  flex-shrink: 1;
  flex-grow: 1;
`;

const HeaderWrapS = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: ${getThemeColor(['greyLight'])};
`;

const FooterWrapS = styled.div`
  width: 100%;
  padding: 10px;
  
  flex-shrink: 0;
  flex-grow: 0;
  
  display: flex;
  justify-content: space-around;
  
  background-color: ${getThemeColor(['greyLight'])};
`;

const FiltersModal = ({ isModalOpen, toggleModal, filtersLocal, dispatchFilterLocalValue, onApply }) => (
  <FiltersModalS
    isOpen={isModalOpen}
    onBackgroundClick={() => toggleModal(false)}
    onEscapeKeydown={() => toggleModal(false)}>
    <HeaderWrapS>
      <Text><Trans i18nKey="modals.filters.header" /></Text>
      <Text color='red' onClick={() => toggleModal(false)}>
        <FontAwesomeIcon icon='times'/>
      </Text>
    </HeaderWrapS>

    <ContentWrapS>
      <CompaniesFilters setFilterLocally={dispatchFilterLocalValue} />
    </ContentWrapS>

    <FooterWrapS>
      <PrimaryButtonS padding='10px 45px' onClick={() => dispatchFilterLocalValue({ filterName: '', value: '', type:'RESET' })}>
        <Trans i18nKey="modals.filters.revertBtn" />
      </PrimaryButtonS>

      <PrimaryButtonS padding='10px 45px' onClick={onApply}>
        <Trans i18nKey="modals.filters.applyBtn" />
      </PrimaryButtonS>
    </FooterWrapS>

  </FiltersModalS>
);

export default FiltersModal;
