export const ITEMS_PER_PAGE = {
  10: 10,
  15: 15,
  20: 20
};

export const ITEMS_FOR_CASOUSEL = 5;

export const REVIEW_PER_PAGE = 10;

export const PROFILE_REVIEW_PER_PAGE = 10;

export const ITEMS_PER_PAGE_CUSTOMER = {
  '25': {
    'countPerPage': 25,
    'isInfinityScroll': false,
  },
  '50': {
    'countPerPage': 50,
    'isInfinityScroll': false,
  },
  'infinity': {
    'countPerPage': 25,
    'isInfinityScroll': true,
  }
};