import { compose, setPropTypes } from 'recompose';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from "yup";
import i18next, { t } from "i18next";
import { goBack, push } from "react-router-redux";
import withWindowSize from "react-window-size";

import { ChangePassComponent } from './ChangePassComponent';
import { withForm } from "../../../../utils/enhancers";
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";
import { profileAction } from "../../../../modules/profile";

const { changePassword } = profileAction;

const getValidationObject = admin => admin ? Yup.object().shape({
  oldPass: Yup.string()
    .required(t('modals.changePass.form.oldPass.errors.required')),
  newPass: Yup.string()
    .required(t('modals.changePass.form.newPass.errors.required'))
    .notOneOf([Yup.ref('oldPass')], i18next.t('modals.changePass.form.newPass.errors.equal')),
}) :
  Yup.object().shape({
    oldPass: Yup.string()
      .required(t('modals.changePass.form.oldPass.errors.required')),
    newPass: Yup.string()
      .required(t('modals.changePass.form.newPass.errors.required'))
      .notOneOf([Yup.ref('oldPass')], i18next.t('modals.changePass.form.newPass.errors.equal')),
    repeatNewPass: Yup.string()
      .required(t('modals.changePass.form.repeatNewPass.errors.required'))
      .oneOf([Yup.ref('newPass')], i18next.t('modals.changePass.form.repeatNewPass.errors.noEqual')),
  });

const enhance = compose(
  connect(() => ({}),{ goBack, push, changePassword }),
  withWindowSize,
  withLanguageOnChange,
  withForm({
    mapPropsToValues: () => ({
      oldPass: '',
      newPass: '',
      repeatNewPass: '',
    }),
    validationSchema: ({ isAdmin }) => getValidationObject(isAdmin),
    handleSubmit: (values, {resetForm, setSubmitting, props: { changePassword, toggleModal, additionalDataToSend }}) => {
      setSubmitting(false);
      changePassword({ ...values, ...additionalDataToSend });
      resetForm();
      toggleModal(false);
    }
  }),
  setPropTypes({
    isAdmin: bool, // this will indicate if modal should be rendered in diff way. We have some diff.
    isModalOpen: bool,
    toggleModal: func,
    additionalDataToSend: object, // additional data which have to be send to server with request.
  })
);

/* THis modal used for company user(which is on client hidden for now) and for admi
* Maybe will be rewriten this modal cause here a bit of mess as we use it for diff scopes.
* TODO: check it.
*  */
export default enhance(ChangePassComponent);
