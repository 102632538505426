import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor, getThemeSize, getScreenSize } from '../../../utils/theme';


const SelectItemsWrap = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 15px;
`;

const SelectItem = styled(DisplayFlexS)`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '50px' }) => height};
  font-size: ${getThemeSize(['normalText'])};
  color: ${getThemeColor(['darkerViolet'])};
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: ${getThemeColor(['white'])};
  cursor: pointer;
  ${({ isOpen }) => isOpen && `
    background: #fff;
  `};

  @media (max-width: ${getScreenSize(['medium'])}) {
    justify-content: space-between;
  }
`;

const SelectDrop = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  padding-top: 5px;
  background-color: ${getThemeColor(['white'])};
  border-top: 1px solid  ${getThemeColor(['grayBlue'])};
  ${({ isOpen }) => isOpen && `
    display: block;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  `};
`;

const SelectDropItem = styled.div`
  cursor: pointer;
  padding: 6px 15px;
  
  color: ${getThemeColor(['primary'])};
  font-size: ${getThemeSize(['normalText'])};
  
  &:hover {
    color: ${getThemeColor(['white'])};
    background-color: ${getThemeColor(['violet'])};
  }
`;

const SelectItemsPerPageCustomer = ({
  onChangeInfinityScroll,
  isInfinityScroll,
  onChangeItemsPerPage,
  itemsPerPage,
  options,
  setPage
}) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <SelectItemsWrap>
      <SelectItem
        aItems="center"
        isOpen={isDropdownOpen}
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        height="auto"
        width="130px"
      >
        {
          (!isInfinityScroll)
            ? <> {itemsPerPage} <Trans i18nKey="companies.profile" /> </>
            : <> <Trans i18nKey="companies.allOption" /> <Trans i18nKey="companies.profile" /> </>
        }
        <FontAwesomeIcon size="1x" icon='angle-down' />
      </SelectItem>
      <SelectDrop isOpen={isDropdownOpen}>
        {options.map((count, idx) =>
          !count.isInfinityScroll ? (
            <SelectDropItem
              key={idx}
              onClick={() => {
                setDropdownOpen(false);
                onChangeInfinityScroll(count.isInfinityScroll);
                dispatch(onChangeItemsPerPage(count.countPerPage));
                dispatch(setPage(1));
              }}
            >
              {count.countPerPage} <Trans i18nKey="companies.profile" />
            </SelectDropItem>
          ) : (
            <SelectDropItem
              key={idx}
              onClick={() => {
                setDropdownOpen(false);
                onChangeInfinityScroll(count.isInfinityScroll);
                dispatch(onChangeItemsPerPage(count.countPerPage));
                dispatch(setPage(1));
              }}
            >
              <Trans i18nKey="companies.allOption" /> <Trans i18nKey="companies.profile" />
            </SelectDropItem>
          )
        )}
      </SelectDrop>
    </SelectItemsWrap>
  );
};

export default SelectItemsPerPageCustomer;
