import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { NOTIFICATION_TYPES } from '../../../modules/app/appConstants';

import { getThemeColor, getThemeSize } from '../../../utils/theme';

const fadeInUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Bar = styled.div`
  width: 100%;

  padding: 30px 0;
  
  position: fixed;
  bottom: 0;

  background-color: ${({ type }) => R.cond([
    [R.equals(NOTIFICATION_TYPES.ERROR), () => getThemeColor(['red'])],
    [R.T, () => getThemeColor(['darkViolet'])]
  ])(type)};
  
  color: ${getThemeColor(['white'])};
  font-size: ${getThemeSize(['bigText'])};
  text-align: center;
  
  animation-name: ${fadeInUpAnimation};
  animation-duration: .5s;
`;

export default connect(({ app }) => ({ notifications: app.notificationBar.messages }))(({ notifications }) =>
  notifications.map(n => (<Bar key={n.id} type={n.msgType} > {n.text} </Bar>)));
