import React  from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getThemeColor } from '../../../../utils/theme';

const SliderHandleS = styled.div`
  background-color: ${getThemeColor(['red'])};
  margin-left: ${`${11 * -0.5}px`};
  margin-top: ${`${11 * -0.5}px`};
  width: 11px;
  height: 11px;
  border-radius: 50%;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  cursor: pointer;

  &:active {
    box-shadow: 0 0 0 8px ${getThemeColor(['redAlpha'])};
  }
`;

export const SliderHandle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps
}) => (
  <SliderHandleS
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{ left: `${percent}%` }}
    {...getHandleProps(id)}
  />
);

SliderHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired
};