import styled from 'styled-components';

import { getThemeColor, getThemeSize } from '../.././../utils/theme';

export const RegularTag = styled.span`
  margin-right: 5px;

  display: inline-block;

  font-size: ${getThemeSize(['tagSize'])};
  text-transform: uppercase;
  color: ${getThemeColor(['brightViolet'])}  
`;