import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import AdvertismentPlaceholderSrc from '../../assets/imgs/AdvertismentPlaceholder.png';

const AdvertismentBannerWrap = styled('a')`
    display: inline-block;
    img {
        width: 100%;
    }
`;

const AdvertismentBanner = ({ bannerUrl, link }) => <AdvertismentBannerWrap href={link} target="_blank">
    <img src={bannerUrl} alt="advertisment banner"/>
</AdvertismentBannerWrap>;

AdvertismentBanner.propTypes = {
    bannerUrl: string,
    link: string
};

AdvertismentBanner.defaultProps = {
    bannerUrl: AdvertismentPlaceholderSrc,
    link: '#'
};

export default AdvertismentBanner;
