import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalsActions } from '../../../../modules/modals';
import { CloseIconS } from '../modalCommonS';

import ConfirmationLayouts, { StyledModalConfirmation } from './confirmationModalsLayout';

const ConfirmationModal = ({ confirmationName, onApproved, onRejected }) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modals[confirmationName]);
  const { open, id: entityId, ...otherModalProps } = modalState || {};

  const setModalStateFor = (name, state) => {
    dispatch(modalsActions.setModalStateFor(name, state));
  };

  const handleClose = () => {
    setModalStateFor(confirmationName, { open: false });
    if (onRejected) {
      onRejected(entityId, otherModalProps);
    }
  };

  return (
    <StyledModalConfirmation isOpen={open} onEscapeKeydown={handleClose}>
      {ConfirmationLayouts[confirmationName](
        setModalStateFor,
        confirmationName,
        onApproved,
        onRejected,
        entityId,
        otherModalProps
      )}

      <CloseIconS onClick={handleClose} icon="times" />
    </StyledModalConfirmation>
  );
};

export default ConfirmationModal;
