import { useState, useEffect } from 'react';

import i18n from '../../i18n';

const useOnChangeLanguage = () => {
  const [, forceUpdate] = useState('');

  useEffect(() => {
    const updateLanguage = () => forceUpdate((prev) => prev + ' ');

    i18n.on('languageChanged', updateLanguage);
    return () => {
      i18n.off('languageChanged', updateLanguage);
    };
  }, []);
};

export default useOnChangeLanguage;
