import { compose, setPropTypes, lifecycle } from 'recompose';
import { func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { goBack, push } from "react-router-redux";

import { ResetPassComponent } from './ResetPassComponent';
import { withForm } from "../../../../utils/enhancers";

import { companyActions } from "../../../../modules/company";
import { authActions } from "../../../../modules/auth";

const { fetchResetPassCompanyAdmin } = companyActions;
const { generatePass } = authActions;


const enhance = compose(
  connect(({  company, auth }) => ({ company, auth }),{ goBack, push, fetchResetPassCompanyAdmin, generatePass }),
  withForm({
    mapPropsToValues: ({ company, auth }) => {
      if (company && company.companyItem && company.companyItem.base && auth.generatedPass) {
       
        return {
          email: company.companyItem.base.email,
          pass: auth.generatedPass
        }
      }
    },
    handleSubmit: (values, {resetForm, setSubmitting,  props: { fetchResetPassCompanyAdmin, toggleModal }}) => {
      setSubmitting(false);
      fetchResetPassCompanyAdmin(values);
      resetForm();
      toggleModal(false);
    }
  }),
  setPropTypes({
    isAdmin: bool, // this will indicate if modal should be rendered in diff way. We have some diff.
    isModalOpen: bool,
    toggleModal: func,
    generatedPass: string
  }),
  lifecycle({
    componentDidMount() {
      this.props.generatePass();  
    }
  })
);

/* THis modal used for company user(which is on client hidden for now) and for admi
* Maybe will be rewriten this modal cause here a bit of mess as we use it for diff scopes.
* TODO: check it.
*  */
export default enhance(ResetPassComponent);
