export const categoryGA = {
  FILTER: "Filter",
  COMPANY_LIST_PAGE: "Company list page",
  HOMEPAGE: "HomePage",
};

export const actionGA = {
  COMPANIES: 'Companies',
  FILTER: 'filter',
};

export const socialGA = {
  BLOG: 'Blog'
};
