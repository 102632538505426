import { all } from 'redux-saga/effects';

import { appSaga } from '../modules/app';
import { authSaga } from '../modules/auth';
import { companySaga } from '../modules/company';
import { reviewSaga } from '../modules/review';
import { profileSaga } from '../modules/profile';
import { companyFilterSagas } from '../modules/companyFilter';

export default function * rootSaga () {
  yield all([
    appSaga(),
    authSaga(),
    companySaga(),
    reviewSaga(),
    profileSaga(),
    companyFilterSagas()
  ]);
}
