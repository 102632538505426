import { appName } from '../../config';

export const moduleName = 'review';
const prefix = `${appName}/${moduleName}`;

export const FETCH_COMPANY_REVIEWS_REQUEST = `${prefix}/FETCH_COMPANY_REVIEWS_REQUEST`;
export const FETCH_COMPANY_REVIEWS_ERROR = `${prefix}/FETCH_COMPANY_REVIEWS_ERROR`;
export const FETCH_COMPANY_REVIEWS_INIT_SUCCESS = `${prefix}/FETCH_COMPANY_REVIEWS_SUCCESS`;
export const FETCH_COMPANY_REVIEWS_ADD_SUCCESS = `${prefix}/FETCH_COMPANY_REVIEWS_ADD_SUCCESS`;

export const FETCH_UNVERIFIED_COMMENTS_REQUEST = `${prefix}/FETCH_UNVERIFIED_COMMENTS_REQUEST`;
export const FETCH_UNVERIFIED_COMMENTS_SUCCESS = `${prefix}/FETCH_UNVERIFIED_COMMENTS_SUCCESS`;
export const FETCH_UNVERIFIED_COMMENTS_ERROR = `${prefix}/FETCH_UNVERIFIED_COMMENTS_ERROR`;

export const FETCH_UNVERIFIED_RATINGS_REQUEST = `${prefix}/FETCH_UNVERIFIED_RATINGS_REQUEST`;
export const FETCH_UNVERIFIED_RATINGS_SUCCESS = `${prefix}/FETCH_UNVERIFIED_RATINGS_SUCCESS`;
export const FETCH_UNVERIFIED_RATINGS_ERROR = `${prefix}/FETCH_UNVERIFIED_RATINGS_ERROR`;

export const FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST = `${prefix}/FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST`;
export const FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS = `${prefix}/FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS`;

export const POST_COMMENT_BY_ADMIN_REQUEST = `${prefix}/POST_COMMENT_BY_ADMIN_REQUEST`;
export const POST_COMMENT_BY_ADMIN_SUCCESS = `${prefix}/POST_COMMENT_BY_ADMIN_SUCCESS`;
export const POST_COMMENT_BY_ADMIN_ERROR = `${prefix}/POST_COMMENT_BY_ADMIN_ERROR`;

export const POST_RATING_BY_ADMIN_REQUEST = `${prefix}/POST_RATING_BY_ADMIN_REQUEST`;
export const POST_RATING_BY_ADMIN_SUCCESS = `${prefix}/POST_RATING_BY_ADMIN_SUCCESS`;
export const POST_RATING_BY_ADMIN_ERROR = `${prefix}/POST_RATING_BY_ADMIN_ERROR`;

export const POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS = `${prefix}/POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS`;

export const EDIT_COMMENT_BY_ADMIN_REQUEST = `${prefix}/EDIT_COMMENT_BY_ADMIN_REQUEST`;
export const EDIT_COMMENT_BY_ADMIN_SUCCESS = `${prefix}/EDIT_COMMENT_BY_ADMIN_SUCCESS`;
export const EDIT_COMMENT_BY_ADMIN_ERROR = `${prefix}/EDIT_COMMENT_BY_ADMIN_ERROR`;

export const EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS = `${prefix}/EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS`;

export const DELETE_COMMENT_BY_ADMIN_REQUEST = `${prefix}/DELETE_COMMENT_BY_ADMIN_REQUEST`;
export const DELETE_COMMENT_BY_ADMIN_SUCCESS = `${prefix}/DELETE_COMMENT_BY_ADMIN_SUCCESS`;
export const DELETE_COMMENT_BY_ADMIN_ERROR = `${prefix}/DELETE_COMMENT_BY_ADMIN_ERROR`;

export const DELETE_RATING_BY_ADMIN_REQUEST = `${prefix}/DELETE_RATING_BY_ADMIN_REQUEST`;
export const DELETE_RATING_BY_ADMIN_SUCCESS = `${prefix}/DELETE_RATING_BY_ADMIN_SUCCESS`;
export const DELETE_RATING_BY_ADMIN_ERROR = `${prefix}/DELETE_RATING_BY_ADMIN_ERROR`;

export const DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS = `${prefix}/DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS`;

export const CREATE_COMPANY_REVIEW_REQUEST = `${prefix}/CREATE_COMPANY_REVIEW_REQUEST`;
export const CREATE_COMPANY_REVIEW_SUCCESS = `${prefix}/CREATE_COMPANY_REVIEW_SUCCESS`;
export const CREATE_COMPANY_REVIEW_ERROR = `${prefix}/CREATE_COMPANY_REVIEW_ERROR`;

export const CREATE_OWNER_ANSWER_REQUEST = `${prefix}/CREATE_OWNER_ANSWER_REQUEST`;
export const CREATE_OWNER_ANSWER_SUCCESS = `${prefix}/CREATE_OWNER_ANSWER_SUCCESS`;
export const CREATE_OWNER_ANSWER_ERROR = `${prefix}/CREATE_OWNER_ANSWER_ERROR`;

export const UPDATE_COMPANY_RATING_REQUEST = `${prefix}/UPDATE_COMPANY_RATING_REQUEST`;

export const TOGGLE_REVIEW_ALERT = `${prefix}/TOGGLE_REVIEW_ALERT`;
