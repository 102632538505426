import styled from 'styled-components';

import { getScreenSize } from '../../../utils/theme';

export const AppWrapperS = styled.div`
  margin: 0;
  width: 100%; // 63% 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
   }
`;

export const ContentS = styled.div`
  flex: 1 0 auto;
  margin-top: 60px;
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 14px;
  }
`;
