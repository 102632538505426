import React from 'react';
import { compose } from 'recompose';
import * as R from 'ramda';
import * as Yup from 'yup';
import i18next from 'i18next';
import styled from 'styled-components';

import { FieldError, PrimaryButtonS, TextInput } from '../../common';
import { withForm } from '../../../../utils/enhancers';


const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const DuplicationCompanyForm =  ({
   values,
   errors,
   touched,
   onSubmit,
   onChange,
   onBlur,
   isSubmitting,
   serverError
 }) => (
  <Form {...{ onSubmit }}>
    <FieldError error={serverError && i18next.t(`general.serverErrorsByCodes.${serverError.messageCode}`)} />

    <InputWrapper >
      <TextInput
        name="companyName"
        type="text"
        placeholder={i18next.t('forms.common.companyName.placeholder')}
        {...{ onChange, onBlur }}
        value={R.prop('companyName', values)}
        isError={R.prop('companyName', errors) && R.prop('companyName', touched)}
        error={errors.companyName}
      />
    </InputWrapper >

    <InputWrapper >
      <TextInput
        name="email"
        type="text"
        placeholder={i18next.t('forms.common.emailForm.placeholder')}
        {...{ onChange, onBlur }}
        value={R.prop('email', values)}
        isError={R.prop('email', errors) && R.prop('email', touched)}
        error={errors.email}
      />
    </InputWrapper >

    <PrimaryButtonS
      disabled={isSubmitting}
      type="submit"
    >
      {i18next.t('forms.common.emailForm.submitBtn')}
    </PrimaryButtonS>
  </Form>
);

const enhancer = compose(
  withForm({
    mapPropsToValues: () => ({ email: '', companyName: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email(i18next.t('forms.common.emailForm.errors.isNotValid'))
      .required(i18next.t('forms.common.emailForm.errors.required')),
      companyName: Yup.string().required(i18next.t('forms.common.companyName.errors.required')),
    }),
    handleSubmit: (values, { setSubmitting, resetForm, props: { submitHandler } }) => {

      setSubmitting(false);

      submitHandler(values);

      resetForm();
    },
  }),
);

export default enhancer(DuplicationCompanyForm)
