import styled from 'styled-components';
import { propOr } from 'ramda';

// for now it was for propotype purposes
export default styled.img`
  width: ${propOr('1.5em', 'size')};
  height: ${propOr('1.5em', 'size')};
  
  display: inline-block;
  
  border-radius: 50%;
`;
