import React from 'react';
import * as R from 'ramda';
import { setPropTypes } from 'recompose';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import { IconBtnS } from './IconButton';

const PaginationWrapS = styled.div`
    display: flex;
    align-items: center;
`;

const Pagination = ({ setPage, pageToRender, itemsPerPage, itemsAmount }) => {

  const totalPages = Math.ceil(itemsAmount/itemsPerPage) + 1;
  const firstRangeNumber = pageToRender <= 2 ? 1 : pageToRender - 2;
  const lastRangeNumber = totalPages <= 5 ? totalPages : firstRangeNumber + 5 < totalPages ? firstRangeNumber + 5 : totalPages;

  return (
        <PaginationWrapS>
            <IconBtnS
                iconName="angle-left"
                color="greyIcon"
                bgColor="greyButton"
                colorHover="white"
                bgColorHover="red"
                sizeCircle="34px"
                onClick={() => setPage(pageToRender - 1)}
                disabled={pageToRender === 1}
            />
            {
                R.range(firstRangeNumber, lastRangeNumber).map(number => (
                    <IconBtnS
                        key={number}
                        number={number}
                        color="greyIcon"
                        bgColor="greyButton"
                        colorHover="white"
                        bgColorHover="red"
                        sizeCircle="34px"
                        active={pageToRender === number}
                        onClick={() => setPage(number)}
                    />
                ))
            }
            <IconBtnS
                iconName="angle-right"
                color="greyIcon"
                bgColor="greyButton"
                colorHover="white"
                bgColorHover="red"
                sizeCircle="34px"
                onClick={() => setPage(pageToRender + 1)}
                disabled={pageToRender === totalPages}
            />
        </PaginationWrapS>
    )
};

export default setPropTypes({
  itemsPerPage: PropTypes.number.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  pageToRender: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
})(Pagination)
