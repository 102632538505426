import * as Yup from 'yup';
import i18next from 'i18next';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import { RegistrationFormComponent } from './registrationFormComponent';
import { withForm } from '../../../../utils/enhancers';

import { authActions } from '../../../../modules/auth';
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";

export const RegistrationUserFormContainer = compose(
  connect(({ auth }) =>
      ({
        serverError: auth.error,
        isRegistrated: auth.isRegistrated,
      }),
    { register: authActions.signUp }),
  withLanguageOnChange,
  withForm({
    mapPropsToValues: () => ({ email: '', password: '', confirmPassword: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(i18next.t('homepage.registrationForm.errors.email.isNotValid'))
        .required(i18next.t('homepage.registrationForm.errors.email.required')),
      password: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.pass.required')),
      confirmPassword: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.confirmPass.required'))
        .oneOf([Yup.ref('password')], i18next.t('homepage.registrationForm.errors.confirmPass.confirmPassword')),
    }),
    handleSubmit: (values, { setSubmitting, resetForm, props: { register } }) => {
      setSubmitting(false);
      register({values});

      resetForm();
    },
  }),
  withProps({ isForCompany: true })
)(RegistrationFormComponent);
