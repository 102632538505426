// support for IE11
import 'react-app-polyfill/ie11';

import React, { Fragment } from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import GoogleAnalytics from 'react-ga';
import { initSentry } from './utils/sentry';
import i18n from './i18n';

import App from './app/app';
import createStore from './redux/store';
import { defaultTheme } from './app/styles/themes';
import initFontAwesomeLib from './app/styles/fontawesome';
import GlobalStyles from './app/styles/globalStyles';

import { GOOGLE_ANALYTICS_KEY } from './config';

initFontAwesomeLib(); // add icons to project

initSentry();

GoogleAnalytics.initialize(GOOGLE_ANALYTICS_KEY);

// Create a store and get back itself and its history object
const { store, history } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <I18nextProvider {...{ i18n }}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Frontload noServerRender={true}>
          <ThemeProvider theme={defaultTheme} >
            <ModalProvider>
              <Fragment>
                <App />
                <GlobalStyles />
              </Fragment>
            </ModalProvider>
          </ThemeProvider>
        </Frontload>
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>
);

const root = document.querySelector('#root');

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast hemletPageWrapper loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
   //If we're not running on the server, just render like normal
  render(Application, root);
}
