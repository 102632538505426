import * as Yup from 'yup';
import { isEmpty, reject } from 'ramda';
import i18next from 'i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { RegistrationFormComponent } from './registrationFormComponent';
import { withForm } from '../../../../utils/enhancers';
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";

import { authActions } from '../../../../modules/auth';


export const RegistrationFormContainer = compose(
  connect(({ auth }) =>
      ({
        serverError: auth.registrationError,
        isRegistrated: auth.isRegistrated,
      }),
    { register: authActions.signUp }),
  withLanguageOnChange,
  withForm({
    mapPropsToValues: () => ({ name: '', phone: '', email: '', password: '', confirmPassword: '' }),
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.name.required')),
      phone: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.phone.required'))
        .matches(new RegExp('(\\(?\\+?\\d{1,3}\\)?[\\s-]+)?\\(?\\d{1,3}\\)?[\\s-]+\\d{3}[\\s-]?\\d{2}[\\s-]?\\d{2}', 'im'),
          i18next.t('homepage.registrationForm.errors.phone.formatIsWrong')),
      email: Yup.string()
        .email(i18next.t('homepage.registrationForm.errors.email.isNotValid'))
        .required(i18next.t('homepage.registrationForm.errors.email.required')),
      password: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.pass.required')),
      confirmPassword: Yup.string()
        .required(i18next.t('homepage.registrationForm.errors.confirmPass.required'))
        .oneOf([Yup.ref('password')], i18next.t('homepage.registrationForm.errors.confirmPass.confirmPassword')),
    }),
    handleSubmit: (values, { setSubmitting, resetForm, props: { register, withNotification } }) => {
      setSubmitting(false);

      values.phone = values.phone.replace(/ /g, ''); // drop spaces which goes from mask

      register(reject(isEmpty)({values, withNotification}));
      resetForm();
    },
  }),
)(RegistrationFormComponent);
