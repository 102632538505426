import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import i18n from 'i18next';

import { LinkS, LinkTextS, Logo, Text } from '../common';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { routes } from "../../routes/routesConfig";
import { getScreenSize, getThemeColor, getThemeSize, getThemeFont } from '../../../utils/theme';
import  useOnChangeLanguage  from "../../../utils/hooks/useOnChangeLanguage";
import { socialLinkGA } from "../../../utils/GA";
import { socialGA } from "../../constants/googleAnalytics";
import { EXTERNAL_LINKS } from '../../constants/externalLinks';


const menuPart = [
  {
    transKey: 'footer.footerMenuPart.forum',
    linkTo: `${EXTERNAL_LINKS.FORUM}`,
    redirect: true
  },
  {
    transKey: 'footer.footerMenuPart.contacts',
    linkTo: routes.CONTACTS
  },
  {
    transKey: 'footer.footerMenuPart.blog',
    linkTo: `${EXTERNAL_LINKS.BLOG}`,
    tracking: socialLinkGA(socialGA.BLOG, `${EXTERNAL_LINKS.BLOG}`),
    redirect: true
  },
  {
    transKey: 'footer.footerMenuPart.faq',
    linkTo: `${EXTERNAL_LINKS.BLOG_FAQ}`,
    tracking: socialLinkGA(socialGA.BLOG, `${EXTERNAL_LINKS.BLOG_FAQ}`),
    redirect: true
  },
  {
    transKey: 'footer.footerMenuPart.fb',
    linkTo: `${EXTERNAL_LINKS.FACEBOOK}`,
    redirect: true,
    targetBlank: true,
    marked: true
  },
];

const lowPart = [
  {
    textToRender: i18n.t('footer.footerLowPart.copyRight', { year: new Date().getFullYear() }),
    linkTo: '/'
  },
  {
    textToRender: i18n.t('footer.footerLowPart.rights'),
    linkTo: '/',
    noLink: true,
  },
  {
    textToRender: i18n.t('footer.footerLowPart.terms.dataProtection'),
    linkTo: '/data-protection'
  },
  {
    textToRender: i18n.t('footer.footerLowPart.terms.agb'),
    linkTo: 'https://treuhand-suche.ch/blog/impressum-und-agb/',
    marked: true,
    external: true
  },
  {
    textToRender: i18n.t('footer.footerLowPart.terms.agbMobile'),
    linkTo: 'https://treuhand-suche.ch/blog/impressum-und-agb/',
    marked: true,
    external: true
  },
];

const WrapperS = styled.div`
  flex-shrink: 1;
  flex-basis: 80px;
  align-self: center;
    
  width: 100%;
  
  margin: 0 auto;
  
  background-color: ${getThemeColor(['greyLight'])};

  @media (max-width: ${getScreenSize(['medium'])}) {
    padding-top: 20px;
  }
`;

const LogoResponsiveS = styled(Logo)`
  margin: 10px 0;
  @media (max-width: ${getScreenSize(['medium'])}) {
      margin: 6px auto;
   }
`

const MenuS = styled.ul`
  display: flex;
  justify-content: ${({ justify }) => justify ? justify : 'space-between' };
  width: ${R.propOr('auto', 'width')};
  margin: ${({ offsetList }) => offsetList ? offsetList : 0 };

  li {
    margin: ${({ offsetListItem }) => offsetListItem ? offsetListItem : 0 };
  }

  a {
    margin: ${({ offsetListLink }) => offsetListLink ? offsetListLink : 0 }
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    display: none;
  }
`;

const MenuMobileS = styled(MenuS)`
  display: none;

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: flex;
    width: 100%;

    display: flex;
    flex-direction: ${R.propOr('row', 'flexDirectionMeduimScreens')};
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;

    a {
      display: inline-block;
      margin: 0 10px;
    }

    li {
      margin: 6px;
      width: auto;
    }
  }
`

const MenuMobileGridS = styled(MenuMobileS)`
  display: none;

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 50%;

    margin: 4px auto 0 auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
`

const ListItemS = styled.li`

  &:nth-child(1) {
    display: none;
  }
`;

const FooterTopPartS = styled.div`
  width: ${getScreenSize(['tabletPC'])};
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 50px 0 15px;
    
  @media (max-width: ${getScreenSize(['tabletPC'])}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin: 0 auto;
    width: 100%;
    padding: 55px 10px 15px;
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    height: auto;
    width:100%;
    
    flex-direction: column;
    align-items: center;
    
    padding: 0;
  }
`;

const FooterBottomPartS = styled.div`
  width: ${getScreenSize(['tabletPC'])};

  height: 60px;
  margin: 0 auto;
  border-top: 2px solid ${getThemeColor(['grayBlue'])};
  padding: 10px 0;
  display:flex;
  align-items: center;
  
   @media (max-width: ${getScreenSize(['tabletPC'])}) { 
    width: 100%;
    padding: 24px 10px 15px;
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 90%;
    height: auto;
    padding: 10px 0;
    margin: 10px auto 6px;
    font-size: ${getThemeSize(['smallText'])};
  }
`;

const LinkFooterTopTextS = styled(LinkTextS)`

@media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${getThemeSize(['mediumText'])};
    font-family: ${getThemeFont(['regular'])};
    font-weight: 700;
    line-height: 15.6px;
  }
`;

const LinkFooterTextS = styled(Text)`
  margin: ${R.prop('margin')};
  color: ${({ color }) => getThemeColor([color || 'greyViolet'])};
  font-size: ${getThemeSize(['normalText'])};

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${getThemeSize(['smallText'])};
    line-height: 13.2px;
  }
`;

const LinkRedirect = styled(LinkS)`
  &:hover span {
    color: ${getThemeColor(['violet'])};
  }
`;

const LinkMenu = styled.a`
  &:hover span {
    color: ${getThemeColor(['violet'])};
  }
`;

const LinkRedirectMobileS = styled(LinkRedirect)`

  @media (max-width: ${getScreenSize(['medium'])}) {
    &:nth-child(1) {
        border-right:  1px solid ${getThemeColor(['greyViolet'])};
        padding-right: 12px;
        margin: 0 12px 4px 12px;
      }

      &:nth-child(2) {
        margin: 0 10px 4px 0;
      }
  }
`;

const Footer = () => {
  useOnChangeLanguage();

  return(
    <WrapperS>
    <FooterTopPartS>
      <LogoResponsiveS />
        <MenuS width="40%">
        {
          menuPart.map(({ transKey, linkTo, redirect, tracking, targetBlank, marked }) => (
            <li key={transKey}>
              {
                redirect ? (
                  <LinkMenu
                    href={linkTo}
                    style={{ textDecoration: 'none' }}
                    target={targetBlank && '_blank'}
                    onClick={() => tracking ? tracking() : null} >
                    <LinkTextS color={marked && 'lighterViolet'}>
                      {i18n.t(transKey)}
                    </LinkTextS>
                  </LinkMenu>
                  ) : (
                    <LinkS to={linkTo}>
                      <LinkTextS color={marked && 'lighterViolet'}>
                        {i18n.t(transKey)}
                      </LinkTextS>
                    </LinkS>
                )
              }

            </li>
          ))
        }
      </MenuS>
      <MenuMobileGridS>
        {
          menuPart.map(({ transKey, linkTo, redirect, tracking, targetBlank, marked }) => (
            <ListItemS key={transKey}>
              {
                redirect ? (
                  <LinkMenu
                    href={linkTo}
                    style={{ textDecoration: 'none' }}
                    target={targetBlank && '_blank'}
                    onClick={() => tracking ? tracking() : null} >
                    <LinkFooterTopTextS color={marked && 'lighterViolet'}>
                      {i18n.t(transKey)}
                    </LinkFooterTopTextS>
                  </LinkMenu>
                  ) : (
                    <LinkS to={linkTo}>
                      <LinkFooterTopTextS color={marked && 'lighterViolet'}>
                        {i18n.t(transKey)}
                      </LinkFooterTopTextS>
                    </LinkS>
                )
              }

            </ListItemS>
          ))
        }
      </MenuMobileGridS>
    </FooterTopPartS>
    <FooterBottomPartS>
      <DisplayFlexS directionScreenMedium="column" justify="space-between">
        <MenuS width="50%" justify='flex-start' offsetListItem='0 2.5px' offsetList='0 -2.5px'>
          {
            lowPart.slice(0, 2).map(({ textToRender, marked }) => (
               <li key={textToRender} >
                <LinkFooterTextS size="normalText" color={marked && 'lighterViolet'}>
                  {textToRender}
                </LinkFooterTextS>
              </li>
            ))
          }
        </MenuS>
        <MenuS width="50%" justify='flex-end' offsetListLink='0 25px' offsetList='0 -25px'>
          {
            lowPart.slice(2, 4).map(({ textToRender, linkTo, marked, external }) => (
                <LinkRedirect
                  key={textToRender}
                  onClick={() => external ? window.location.assign(linkTo) : null}
                  to={!external ? linkTo : ''}
                >
                  <LinkFooterTextS color={marked && 'lighterViolet'}>
                  {textToRender}
                </LinkFooterTextS>
              </LinkRedirect>
            ))
          }
        </MenuS>
        <MenuMobileS justify='center' offsetListLink='0 25px' offsetList='0 -25px'>
          {
            [lowPart[2], lowPart[4]].map(({ textToRender, linkTo, marked, external }) => (
                <LinkRedirectMobileS
                  key={textToRender}
                  onClick={() => external ? window.location.assign(linkTo) : null}
                  to={!external ? linkTo : ''}
                >
                  <LinkFooterTextS color={marked && 'lighterViolet'}>
                  {textToRender}
                </LinkFooterTextS>
              </LinkRedirectMobileS>
            ))
          }
        </MenuMobileS>
        <MenuMobileS justify='center' offsetListItem='0 2.5px' offsetList='0 -2.5px'>
          {
            lowPart.slice(0, 2).map(({ textToRender, marked }) => (
               <li key={textToRender} >
                <LinkFooterTextS size="smallText" color={marked && 'lighterViolet'}>
                  {textToRender}
                </LinkFooterTextS>
              </li>
            ))
          }
        </MenuMobileS>
      </DisplayFlexS>
    </FooterBottomPartS>
  </WrapperS>
  );
};

export default Footer;
