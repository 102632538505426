import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { StyledModal } from "../modalCommonS";
import {Text} from "../../common";
import Divider from "../../common/Divider";
import { getThemeColor, getThemeFont, getThemeSize } from "../../../../utils/theme";


const ChangeLogoS = styled(StyledModal)`
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  padding: 0
`;

const HeaderS = styled(Text)`
  margin: 30px auto;
  font-size: ${getThemeSize(['headers', 'authModal'])}; 
  color: ${getThemeColor(['violet'])};
  font-family: ${getThemeFont(['bold'])};
`;

const ButtonS = styled.button`
  margin: 20px auto;
  outline: none;
  font-size: ${getThemeSize(['normalText'])}; 
  font-family: ${getThemeFont(['bold'])};
  color: ${({color}) => getThemeColor([color || 'violet'])};
  background-color: ${getThemeColor(['white'])};
  border:none;
  
  &:hover {
    cursor: pointer;
  }
`;

const LabelS = styled.label`
    display: inline-block;
    margin: 20px auto;
    padding: 6px 12px;
    font-size: ${getThemeSize(['normalText'])}; 
    font-family: ${getThemeFont(['bold'])};
    color: ${({color}) => getThemeColor([color || 'violet'])};
    background-color: ${getThemeColor(['white'])};
    cursor: pointer;
`;

const InputS = styled.input`
  &[type="file"]{
    display: none;
}
`;

export const ChangeLogoModal = ({isModalOpen, toggleModal, updateLogo, removeLogo}) => (
  <ChangeLogoS
    isOpen={isModalOpen}
    onBackgroundClick={() => toggleModal(false)}
    onEscapeKeydown={() => toggleModal(false)}
  >
    <HeaderS>
      <Trans i18nKey="modals.changeLogo.header" />
    </HeaderS>

    <Divider/>
    <InputS type="file" id="logo" onChange={updateLogo}/>
    <LabelS htmlFor="logo">
        <Trans i18nKey="modals.changeLogo.selectImage" />
    </LabelS>

    <Divider/>
    <ButtonS color='red' onClick={removeLogo}>
      <Trans i18nKey="modals.changeLogo.deleteImage" />
    </ButtonS>

    <Divider />
    <ButtonS color='violet' onClick={() => toggleModal(false)}>
      <Trans i18nKey="modals.changeLogo.close" />
    </ButtonS>

  </ChangeLogoS>
);


