import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail } from './SliderRail';
import { SliderHandle } from './SliderHandle';
import { SliderTracks } from './SliderTracks';

const RangeSliderS = styled.div`
  position: relative;
  width: 100%;
`;

export const RangeSlider = ({
  values,
  domain,
  mode,
  step,
  onUpdate
}) => (
  <RangeSliderS>
    <Slider
      mode={mode}
      step={step}
      domain={domain}
      onUpdate={onUpdate}
      onChange={onUpdate}
      values={values}
    >
      <Rail>
        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <>
            {handles.map(handle => (
              <SliderHandle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
              />
            ))}
          </>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <>
            {tracks.map(({ id, source, target }) => (
              <SliderTracks
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </>
        )}
      </Tracks>
    </Slider>
  </RangeSliderS>
);

RangeSlider.propTypes = {
  values: PropTypes.array.isRequired,
  domain: PropTypes.array.isRequired,
  step: PropTypes.number.isRequired,
  mode: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired
};