import React from 'react';
import { arrayOf, element, func, oneOfType, shape, string, oneOf } from 'prop-types';

import { IconBtnS } from '../IconButton';

import {
  TableHeadS,
  TableHeadTHS,
  TableRow,
  TableS,
  TableWrapS
} from './TableS';

const TableComponent = ({ columns, data, maxWidth }) => (
  <TableWrapS maxWidth={maxWidth}>
    <TableS>
      <TableHeadS>
        <tr>
          {
            columns.map((column) => (
              <TableHeadTHS key={column.title}>
                <span>
                  {column.title}
                </span>
                {column.sort && (
                  /*Current implementation of sort assume that we have 3 states of sort: 1, 0, -1
                  * 1 - ASC
                  * 0 - none
                  * -1 - DESC
                  * Allclients of this table have to implement such approuch
                  * For example see company app/components/adminPanel/companies/companiesContainer.js
                  * */
                  <span style={{ display: 'inline-flex', flexDirection: 'column' }}>
                    <IconBtnS
                      iconName="angle-up"
                      color={column.sort.sortState === 1 ? 'violet' : 'gray_1'}
                      sizeCircle="10px"
                      displayBlock="inline-block"
                      onClick={() => column.sort.sortState === 1 ? column.sort.sortFunc(0) : column.sort.sortFunc(1)}
                      direction={column.sort.sortState}
                    />
                    <IconBtnS
                      iconName="angle-down"
                      color={column.sort.sortState === -1 ? 'violet' : 'gray_1'}
                      sizeCircle="10px"
                      displayBlock="inline-block"
                      onClick={() => column.sort.sortState === -1 ? column.sort.sortFunc(0) : column.sort.sortFunc(-1)}
                      direction={column.sort.sortState}
                    />
                  </span>
                )}
              </TableHeadTHS>
            ))
          }
        </tr>
      </TableHeadS>

      <tbody>
      {
        data.map(item => (
          <TableRow key={item + Math.random()}>
            {
              Object.values(item).map((key) => (
                <td key={item.name + Math.random()}>{key}</td>
              ))
            }
          </TableRow>

        ))
      }
      </tbody>
    </TableS>
  </TableWrapS>
);

TableComponent.propTypes = {
  columns: arrayOf(shape({
    title: oneOfType([string, element]).isRequired,
    sort: shape({ sortFunc: func, sortState: oneOf([-1, 0, 1]) }),
  })).isRequired,
  data: arrayOf(shape({
    name: oneOfType([string, element]),
    data: oneOfType([string, element]),
    location: oneOfType([string, element]),
    phone: oneOfType([string, element]),
    email: oneOfType([string, element]),
    //lastChange: shape({ // TODO: as it not implemented we don`t have specific structure, uncomment it once this will be done.
    //  lastData: oneOfType([string, element]),
    //  byChange: oneOfType([string, element]),
    //}),
    action: oneOfType([string, element]),
  })).isRequired,
  maxWidth: string
};

export default TableComponent;
