import { compose, lifecycle, withHandlers } from 'recompose';
import GoogleAnalytics from 'react-ga';

const withGATracker = compose(
    withHandlers({
      trackPage: () => page => {
      GoogleAnalytics.set({
        page,
        // ...options, commented as it use anywhere
      });
      GoogleAnalytics.pageview(page);
    }}),
    lifecycle({
      componentDidMount() {
        // eslint-disable-next-line
        const page = this.props.location.pathname + this.props.location.search;
        this.props.trackPage(page);
      },
      componentDidUpdate(prevProps) {
        const currentPage = prevProps.location.pathname + prevProps.location.search;
        const nextPage = this.props.location.pathname + this.props.location.search;
        if (currentPage !== nextPage) {
          this.props.trackPage(nextPage);
        }
      }
    })
);

export default withGATracker;
