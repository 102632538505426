import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { toggleModal } from '../../../../modules/app/appActions';
import { cleanUpAuthErrors, resetPass, resolveRegistrationConflict } from '../../../../modules/auth/authActions';
import useOnChangeLanguage from '../../../../utils/hooks/useOnChangeLanguage';
import { getScreenSize } from '../../../../utils/theme';
import { authModalLayouts } from '../../../constants/modals';
import { CloseIconS, StyledModal } from '../modalCommonS';

import { LoginLayout, RegistrationLayout, ResetPass, SendEmail } from './authModalLayouts';

const StyledModalS = styled(StyledModal)`
  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
    padding: 0;
    height: 100%;
  }
`;

const AuthModal = () => {
  useOnChangeLanguage();

  const dispatch = useDispatch();

  const modal = useSelector((state) => state.app.authModal);
  const auth = useSelector((state) => state.auth);

  const handleClose = () => {
    dispatch(toggleModal({ open: false, layout: null }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanUpAuthErrors());
    };
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');

    if (modal.open) {
      rootElement.style.overflow = 'hidden';
    } else {
      rootElement.style.overflow = '';
    }
    return () => {
      rootElement.style.overflow = '';
    };
  }, [modal.open]);

  return (
  <StyledModalS
    isOpen={modal.open}
    onEscapeKeydown={handleClose}
    afterClose={() => dispatch(cleanUpAuthErrors())}
  >
    <CloseIconS  onClick={handleClose} icon='times'/>
    { modal.layout === authModalLayouts.registration 
      && <RegistrationLayout setModalStateTo={(state) => dispatch(toggleModal(state))} /> }
    { modal.layout === authModalLayouts.login 
      &&  <LoginLayout setModalStateTo={(state) => dispatch(toggleModal(state))} /> }
    { modal.layout === authModalLayouts.resetPass 
      && <ResetPass auth={auth} resetPass={(data) => dispatch(resetPass(data))} /> }
    { modal.layout === authModalLayouts.resolveRegistrationConflict 
      && <SendEmail resolveRegistrationConflict={(data) => dispatch(resolveRegistrationConflict(data))} auth={auth} /> }
  </StyledModalS>
)};

export default AuthModal;
