import axios from 'axios';

import { getAuthHeader } from '../auth';

const makeRequest = async (method, url, data) => {
  try {
    const response = await axios({
      url,
      data,
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

/**
 * Provide Request for API
 * */
export const get = url => makeRequest('get', url, null);
export const post = (url, data) => makeRequest('post', url, data);
export const putApi = (url, data) => makeRequest('put', url, data); // putApi cause in redux-saga exist put
export const remove = (url, data) => makeRequest('delete', url, data);
export const patch = (url, data) => makeRequest('patch', url, data);
