import * as Yup from 'yup';
import i18next from 'i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { EmailFormComponent } from './emailFormComponent';
import { withForm } from '../../../../../utils/enhancers';
import { withLanguageOnChange } from "../../../../../utils/enhancers/withLanguageChange";

/*
*  should get props:
*
*  submitHandler
*
* */

export const EmailFormContainer = compose(
  connect(null),
  withLanguageOnChange,
  withForm({
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(i18next.t('forms.common.emailForm.errors.isNotValid'))
        .required(i18next.t('forms.common.emailForm.errors.required')),
    }),
    handleSubmit: (values, { setSubmitting, resetForm, props: { submitHandler } }) => {
      setSubmitting(false);

      submitHandler(values);

      resetForm();
    },
  }),
)(EmailFormComponent);
