import React from 'react';
import * as R from 'ramda';
import { setPropTypes } from 'recompose';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import { IconBtnSCustomer } from './IconButtonCustomer';

const PaginationWrapS = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
`;

const PaginationCustomer = ({ setPage, pageToRender, itemsPerPage, itemsAmount }) => {

  const totalPages = Math.ceil(itemsAmount/itemsPerPage); 
  const firstRangeNumber = (pageToRender <= 3 || totalPages === 4) ? 2 : pageToRender < totalPages - 2 ? pageToRender - 1 : totalPages - 3;
  const lastRangeNumber = totalPages <= 5 ? totalPages : firstRangeNumber + 3 < totalPages ? firstRangeNumber + 3 : totalPages;
  
  return (
      (!!totalPages) && (
        <PaginationWrapS>
            <IconBtnSCustomer
                typeArrow={true}
                color="greyIcon"
                colorHover="greyIcon"
                bgColorHover="grayBlue"
                sizeWidth="65px"
                onClick={() => setPage(pageToRender - 1)}
                disabled={pageToRender === 1}
            />
            <IconBtnSCustomer
                key={1}
                number={1}
                color="greyIcon"
                colorHover="greyIcon"
                colorActive="white"
                bgColorHover="grayBlue"
                bgColorActive="violet"
                active={pageToRender === 1}
                onClick={() => setPage(1)}
            />
            {
              (firstRangeNumber >= 3) && (
                <IconBtnSCustomer
                    className="sm-display-none"
                    number="..."
                    color="greyIcon"
                    disabled={true}
                />
              )
            }
            {
              R.range(firstRangeNumber, lastRangeNumber).map(number => (
                <IconBtnSCustomer
                    className="sm-display-none"
                    key={number}
                    number={number}
                    color="greyIcon"
                    colorHover="greyIcon"
                    colorActive="white"
                    bgColorHover="grayBlue"
                    bgColorActive="violet"
                    active={pageToRender === number}
                    onClick={() => setPage(number)}
                />
              ))
            }
            {
              (lastRangeNumber - 3) < (totalPages - 3) && (
                <IconBtnSCustomer
                    className="sm-display-none"
                    number="... "
                    color="greyIcon"
                    disabled={true}
                />
              )
            }
            {
              ( 1 < pageToRender && pageToRender < totalPages) && (
                <>
                    <IconBtnSCustomer
                        className="sm-display-flex"
                        key={pageToRender}
                        number={pageToRender}
                        color="greyIcon"
                        colorHover="greyIcon"
                        colorActive="white"
                        bgColorHover="grayBlue"
                        bgColorActive="violet"
                        active={pageToRender}
                        onClick={() => setPage(pageToRender)}
                    />
                </>
              )
            }
            {
              (totalPages > 1) && (
                <IconBtnSCustomer
                    key={totalPages}
                    number={totalPages}
                    color="greyIcon"
                    colorHover="greyIcon"
                    colorActive="white"
                    bgColorHover="grayBlue"
                    bgColorActive="violet"
                    active={pageToRender === totalPages}
                    onClick={() => setPage(totalPages)}
                />
              )
            }
            <IconBtnSCustomer
                typeArrow={true}
                color="greyIcon"
                rotate={true}
                colorHover="greyIcon"
                bgColorHover="grayBlue"
                sizeWidth="65px"
                onClick={() => setPage(pageToRender + 1)}
                disabled={pageToRender === totalPages}
            />
        </PaginationWrapS>
    )
  )
};

export default setPropTypes({
  itemsPerPage: PropTypes.number.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  pageToRender: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
})(PaginationCustomer)
