import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor, getThemeSize, getScreenSize } from '../../../utils/theme';

import { FieldError } from './Error';
import { InputLabel } from './TextInput';


const SelectItemsWrap = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 15px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '50px'};
  flex-basis: ${({ closeBtnHandler }) => (closeBtnHandler ? '95%' : '100%')};
`;

const SelectItem = styled(DisplayFlexS)`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  font-size: ${getThemeSize(['normalText'])};
  color: ${getThemeColor(['darkerViolet'])};
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
  overflow: hidden;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: ${getThemeColor(['white'])};
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    `
    background: #fff;
  `};

  @media (max-width: ${getScreenSize(['medium'])}) {
    justify-content: space-between;
  }
`;

const SelectDrop = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  padding-top: 5px;
  background-color: ${getThemeColor(['white'])};
  border-top: 1px solid ${getThemeColor(['grayBlue'])};
  ${({ isOpen }) =>
    isOpen &&
    `
    display: block;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  `};
  option {
    cursor: pointer;
    padding: 6px 15px;
    color: ${getThemeColor(['primary'])};
    font-size: ${getThemeSize(['normalText'])};

    &:hover {
      color: ${getThemeColor(['white'])};
      background-color: ${getThemeColor(['violet'])};
    }
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${getThemeColor(['red'])};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-left: 10px;
  }
`;

const SelectNew = ({
  children,
  labelColor,
  label,
  error,
  name,
  isError,
  closeBtnHandler,
  width,
  value,
  values = {},
  ...rest
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Fragment>
      {label ? (
        <InputLabel labelColor={labelColor} htmlFor={name}>
          {label}
        </InputLabel>
      ) : null}
      <DisplayFlexS justify="space-between" alignItemsScreenMedium="center" aItems="center" basis={width}>
        <SelectItemsWrap {...{ name, isError, closeBtnHandler }} {...rest} className="select-items">
          <SelectItem aItems="center" isOpen={isDropdownOpen} onClick={() => setDropdownOpen(!isDropdownOpen)}>
            {Object.keys(values).map((key) => {
              if (values[key].value === value) {
                return i18n.t(values[key].transPath);
              }
              return null;
            })}
            <FontAwesomeIcon size="1x" icon="angle-down" />
          </SelectItem>
          <SelectDrop isOpen={isDropdownOpen} onClick={() => setDropdownOpen(!isDropdownOpen)}>
            {children}
          </SelectDrop>
        </SelectItemsWrap>
        {closeBtnHandler ? <DeleteIcon onClick={closeBtnHandler} icon="times" /> : null}
      </DisplayFlexS>
      {isError && error ? <FieldError {...{ error }} /> : null}
    </Fragment>
  );
};

export default SelectNew;
