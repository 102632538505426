import styled from 'styled-components';

import { getThemeColor, getThemeSize, getThemeFont, getScreenSize } from '../../../../utils/theme';
import { Divider, TextInput, PrimaryButtonWrapS, PrimaryButtonS } from '../../common';

export const FiltersWrapS = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-right: 25px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    overflow-y: auto;
    height: 80%;
    margin-bottom: 30px;
    border-top: 1px solid ${getThemeColor(['grayBlue'])};
    padding: 0;
  }
`;

export const FilterWrapS = styled.div`
  margin-bottom: 15px;

  img {
    /* Style for redCaht icon in error. */
    transform: translateY(2px);
  }
  .error-message {
    color: ${getThemeColor(['red'])};
    font-size: ${getThemeSize(['bigText'])};
  }

  .filter-header span {
    white-space: nowrap;
    max-width: 85%;
  }

  .filter-header {
    margin-bottom: 10px;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 0;

    .filter-header {
      padding: 15px 24px;
      margin-bottom: 0;
      justify-content: space-between;
    }
    .histogram-btns-wrapper {
      padding: 0 24px;
      flex-wrap: wrap;
      & > button {
        width: 48%;
        min-width: 110px;
      }
    }
    .histogram-wrapper {
      padding: 10px 24px;
      display: flex;
      flex-direction: column;
      & .histogram-buttons {
        & button {
          width: 48%;
          min-width: 110px;
          margin: 0 5px;
        }
      }
    }
  }
`;

export const ArrowIconWrap = styled.div`
  display: flex;
  justify-content: center;
  color: ${getThemeColor(['red'])};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotateX(0deg)' : 'rotateX(180deg)')} translateX(10px);
  margin-top: -5px;

  &:hover {
    background-color: ${getThemeColor(['grayBlue'])};
    cursor: pointer;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 10px;
  border-top: 1px solid ${getThemeColor(['grayBlue'])};
`;

export const OptionsWrapS = styled.div`
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  transition: height 0.5s;
  max-height: 180px;
  overflow-y: auto;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-top: 15px;
    padding: 0 24px 13px 38px;
  }
`;

export const FilterOptionContainer = styled.div`
  align-content: center;
  display: flex;
  margin-bottom: 5px;

  span {
    font-size: ${getThemeSize(['normalText'])};
    color: ${getThemeColor(['primary'])};
    margin-left: 8px;

    &:hover {
      color: ${getThemeColor(['violet'])};
      cursor: pointer;
    }
  }
`;

export const SearchInput = styled(TextInput)`
  border: 1px solid ${getThemeColor(['greyButton'])};
  margin-bottom: 10px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 0 24px;
    width: 90%;
  }
`;

export const HistogramBtnS = styled(PrimaryButtonWrapS)`
  width: 90px;
  padding: 3px;
  margin: 9px 10px;

  background-color: ${({ isActive }) => (isActive ? getThemeColor(['greyBlueLightAlpha']) : getThemeColor(['white']))};
  color: ${getThemeColor(['violet'])};
  border: 0.5px solid ${getThemeColor(['violet'])};
  font-size: ${({ fz }) => getThemeSize([fz || 'buttonText'])};

  span {
    font-family: ${({ isActive }) => (isActive ? getThemeFont(['bold']) : getThemeFont(['regular']))};
  }

  &:hover {
    background-color: ${getThemeColor(['greyBlueLightAlpha'])};
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 6px 2px;
    width: 50%;
  }
`;

export const HistogramSecondaryBtnS = styled(PrimaryButtonWrapS)`
  width: 25%;
  padding: 6px;
  margin-bottom: 15px;

  background-color: ${({ isActive }) =>
    isActive ? getThemeColor(['greyBlueLightAlpha']) : getThemeColor(['greyLight'])};
  color: ${getThemeColor(['violet'])};
  border-radius: 2px;
  font-size: ${({ fz }) => getThemeSize([fz || 'buttonText'])};

  span {
    font-family: ${({ isActive }) => (isActive ? getThemeFont(['bold']) : getThemeFont(['regular']))};
  }

  &:hover {
    background-color: ${getThemeColor(['greyBlueLightAlpha'])};
    box-shadow: none;
  }
`;

export const SubmitFilterS = styled(PrimaryButtonS)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 163px;
  height: 43px;
  text-transform: uppercase;
  font-size: ${getThemeSize(['largeText'])};
  padding: 12px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 2px;
  font-family: ${getThemeFont(['regular'])};
  margin: 0 auto 5rem;
  padding: 13px 12px 12px;
  font-size: 15px;
`;
