import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, number } from 'prop-types';
import React from 'react';
import SliderSlick from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getScreenSize, getThemeSize, getThemeColor } from '../../../utils/theme';

const SliderS = styled(SliderSlick)`
  &.slick-slider {
    position: static;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
`;

const SliderWr = styled.div`
  padding: 0 50px;
  position: relative;

  @media (max-width: ${getScreenSize(['medium'])}) {
    padding: 0 0 0 24px;
  }
`;

const SliderArrowPrevS = styled.div`
  &.slick-prev {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: ${getThemeSize(['headers', 'authModal'])};
    color: ${getThemeColor(['red'])};
    transition: all 0.3s ease-in-out;

    &:before {
      display: none;
    }

    &:hover {
      background-color: ${getThemeColor(['red'])};
      color: ${getThemeColor(['white'])};
    }

    &:active {
      background-color: ${getThemeColor(['darkRed'])};
      color: ${getThemeColor(['white'])};
    }
  }

  &.slick-disabled {
    color: ${getThemeColor(['gray_1'])};

    &:hover {
      background-color: transparent;
      color: ${getThemeColor(['gray_1'])};
    }
  }
`;

const SliderArrowNextS = styled.div`
  &.slick-next {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: ${getThemeSize(['headers', 'authModal'])};
    color: ${getThemeColor(['red'])};
    transition: all 0.3s ease-in-out;

    &:before {
      display: none;
    }

    &:hover {
      background-color: ${getThemeColor(['red'])};
      color: ${getThemeColor(['white'])};
    }

    &:active {
      background-color: ${getThemeColor(['darkRed'])};
      color: ${getThemeColor(['white'])};
    }
  }

  &.slick-disabled {
    color: ${getThemeColor(['gray_1'])};

    &:hover {
      background-color: transparent;
      color: ${getThemeColor(['gray_1'])};
    }
  }
`;

const ArrowPrev = ({ className, onClick }) => (
  <SliderArrowPrevS className={className} onClick={onClick}>
    <FontAwesomeIcon icon="angle-left" />
  </SliderArrowPrevS>
);

const ArrowNext = ({ className, onClick }) => (
  <SliderArrowNextS className={className} onClick={onClick}>
    <FontAwesomeIcon icon="angle-right" />
  </SliderArrowNextS>
);

const Slider = ({ slidesPerView, sliderItems }) => {
  const settings = {
    infinite: false,
    slidesToShow: slidesPerView,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
  };

  return (
    <SliderWr>
      <SliderS {...settings}>{sliderItems}</SliderS>
    </SliderWr>
  );
};

Slider.propTypes = {
  slidesPerView: number.isRequired,
  sliderItems: array.isRequired,
};

export default Slider;
