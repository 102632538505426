import { appName } from '../../config';

export const moduleName = 'companyFilter';
const prefix = `${appName}/${moduleName}`;

// Const
export const SET_FILTER_VALUE = `${prefix}/SET_FILTER_VALUE`; // used in app from compoents etc
export const SET_FILTER = `${prefix}/SET_FILTER`; // used internally in redux
export const SET_HISTOGRAM_FILTER = `${prefix}/SET_HISTOGRAM_FILTER`;
export const SET_ZIP_FILTER_VALUE = `${prefix}/SET_ZIP_FILTER_VALUE`;
export const SET_ZIP_FILTER = `${prefix}/SET_ZIP_FILTER`;
export const MAKE_SEARCH = `${prefix}/MAKE_SEARCH`;
export const SET_SELECT_OPTION = `${prefix}/SET_SELECT_OPTION`;
export const SET_ALL_FILTER_VALUE = `${prefix}/SET_ALL_FILTER_VALUE`;
export const APPLY_FILTERS = `${prefix}/APPLY_FILTERS`;
export const SET_SORT_VALUE = `${prefix}/SET_SORT_VALUE`;
export const RESET_FILTERS = `${prefix}/RESET_FILTERS`;
export const RESET_FILTERS_REQUEST = `${prefix}/RESET_FILTERS_REQUEST`;
export const SYNC_SELECTS_WITH_FILTERS = `${prefix}/SYNC_SELECTS_WITH_FILTERS`;
export const APPLY_SELECTS_VALUES_TO_FILTERS = `${prefix}/APPLY_SELECTS_VALUES_TO_FILTERS`;
export const SET_SEARCH_STRING = `${prefix}/SET_SEARCH_STRING`;
export const SET_ITEMS_PER_PAGE_CUSTOMER = `${prefix}/SET_ITEMS_PER_PAGE_CUSTOMER`;
export const SET_PAGE = `${prefix}/SET_PAGE`;
export const SET_INFINITY_SCROLL = `${prefix}/SET_INFINITY_SCROLL`;
