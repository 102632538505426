import { uniq } from 'ramda';

import {
  CREATE_COMPANY_REVIEW_ERROR,
  CREATE_COMPANY_REVIEW_REQUEST,
  CREATE_COMPANY_REVIEW_SUCCESS,
  CREATE_OWNER_ANSWER_ERROR,
  CREATE_OWNER_ANSWER_REQUEST,
  CREATE_OWNER_ANSWER_SUCCESS,
  FETCH_COMPANY_REVIEWS_ADD_SUCCESS,
  FETCH_COMPANY_REVIEWS_ERROR,
  FETCH_COMPANY_REVIEWS_INIT_SUCCESS,
  FETCH_COMPANY_REVIEWS_REQUEST,
  FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS,
  FETCH_UNVERIFIED_COMMENTS_SUCCESS,
  FETCH_UNVERIFIED_COMMENTS_ERROR,
  TOGGLE_REVIEW_ALERT,
  POST_COMMENT_BY_ADMIN_ERROR,
  POST_COMMENT_BY_ADMIN_SUCCESS,
  EDIT_COMMENT_BY_ADMIN_ERROR,
  EDIT_COMMENT_BY_ADMIN_SUCCESS,
  DELETE_COMMENT_BY_ADMIN_ERROR,
  DELETE_COMMENT_BY_ADMIN_SUCCESS,
  DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS,
  FETCH_UNVERIFIED_RATINGS_SUCCESS,
  FETCH_UNVERIFIED_RATINGS_ERROR,
  DELETE_RATING_BY_ADMIN_SUCCESS,
  POST_RATING_BY_ADMIN_SUCCESS,
  POST_RATING_BY_ADMIN_ERROR
} from './reviewConstants';

import { COMMENT_WORKFLOW_STATES } from '../../app/constants/comments';
import { RATING_WORKFLOW_STATES } from '../../app/constants/ratings';

const initialState = {
  loading: false,
  amount: 0,
  reviews: [],
  isAlertOpen: false,
  error: null,
  unverifiedComments: [], // for admin
  totalCommentsCount: 0, // for admin
  unhandledAnswersCount: 0, // for admin
  unhandledCommentsCount: 0, // for admin
  unhandledRatingsCount: 0, // for admin
  unverifiedRatings: [], // for admin
  totalRatingsCount: 0, // for admin
};

export default function reducer(state = initialState, action) {
  const { type, error, payload } = action;

  switch (type) {
    case FETCH_COMPANY_REVIEWS_REQUEST:
    case CREATE_COMPANY_REVIEW_REQUEST:
    case CREATE_OWNER_ANSWER_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case FETCH_COMPANY_REVIEWS_INIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: [ ...payload.list ],
        amount: payload.count,
      }
    }
    case FETCH_COMPANY_REVIEWS_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: uniq([...state.reviews , ...payload.list]),
        amount: payload.count,
      }
    }
    case CREATE_COMPANY_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case CREATE_OWNER_ANSWER_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: state.reviews.map( item => item._id === payload._id ? payload : item)
      }
    }
    case TOGGLE_REVIEW_ALERT: {
      return {
        ...state,
        isAlertOpen: payload
      }
    }
    case FETCH_UNHANDLED_COMMENTS_COUNTS_SUCCESS: {
      const { unhandledAnswersCount, unhandledCommentsCount, unhandledRatingsCount } = payload;

      return {
        ...state,
        unhandledAnswersCount,
        unhandledCommentsCount,
        unhandledRatingsCount
      }
    }
    case FETCH_UNVERIFIED_COMMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCommentsCount: payload.total,
        unverifiedComments: payload.replaceExisting ? payload.data : [...state.unverifiedComments, ...payload.data],
      }
    }
    case FETCH_UNVERIFIED_RATINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalRatingsCount: payload.total,
        unverifiedRatings: payload.replaceExisting ? payload.data : [...state.unverifiedRatings, ...payload.data],
      }
    }
    case POST_COMMENT_BY_ADMIN_SUCCESS: {
      const { commentId } = payload;
      const posted = state.unverifiedComments.find(c => c._id === commentId );
      const countKeyToUpdate = posted.companyAnswer ? 'unhandledAnswersCount' : 'unhandledCommentsCount';

      return {
        ...state,
        [countKeyToUpdate]: posted.workflowState === COMMENT_WORKFLOW_STATES.NEW ? state[countKeyToUpdate] - 1 : state[countKeyToUpdate],
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId
            ? {
              ...c,
              workflowState: COMMENT_WORKFLOW_STATES.POSTED,
              postedAt: new Date()
            }
            : c )
      }
    }
    case POST_COMMENT_ANSWER_BY_ADMIN_SUCCESS: {
      const { commentId } = payload;
      const posted = state.unverifiedComments.find(c => c._id === commentId );

      return {
        ...state,
        unhandledAnswersCount: posted.companyAnswer.workflowState === COMMENT_WORKFLOW_STATES.NEW
          ? state.unhandledAnswersCount - 1
          : state.unhandledAnswersCount,
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId
            ? {
              ...c,
            companyAnswer: {
              ...c.companyAnswer,
              workflowState: COMMENT_WORKFLOW_STATES.POSTED,
              postedAt: new Date()
            }
            }
            : c )
      }
    }
    case EDIT_COMMENT_BY_ADMIN_SUCCESS: {
      const { commentId, text } = payload;
      const edited = state.unverifiedComments.find(c => c._id === commentId );
      const countKeyToUpdate = edited.companyAnswer ? 'unhandledAnswersCount' : 'unhandledCommentsCount';

      return {
        ...state,
        [countKeyToUpdate]: edited.workflowState === COMMENT_WORKFLOW_STATES.NEW ? state[countKeyToUpdate] - 1 : state[countKeyToUpdate],
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId ? { ...c, text, workflowState: COMMENT_WORKFLOW_STATES.EDITED, editedAt: new Date() } : c )
      }
    }
    case EDIT_COMMENT_ANSWER_BY_ADMIN_SUCCESS: {
      const { commentId, text } = payload;
      const edited = state.unverifiedComments.find(c => c._id === commentId );

      return {
        ...state,
        unhandledAnswersCount: edited.companyAnswer.workflowState === COMMENT_WORKFLOW_STATES.NEW
          ? state.unhandledAnswersCount - 1
          : state.unhandledAnswersCount,
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId
            ? { ...c,
                companyAnswer: {
                  ...c.companyAnswer,
                  text,
                  workflowState: COMMENT_WORKFLOW_STATES.EDITED,
                  editedAt: new Date()
                }
              }
            : c )
      }
    }
    case DELETE_COMMENT_BY_ADMIN_SUCCESS: {
      const { commentId } = payload;
      const deleted = state.unverifiedComments.find(c => c._id === commentId );
      const countKeyToUpdate = deleted.companyAnswer ? 'unhandledAnswersCount' : 'unhandledCommentsCount';

      return {
        ...state,
        [countKeyToUpdate]: deleted.workflowState === COMMENT_WORKFLOW_STATES.NEW ? state[countKeyToUpdate] - 1 : state[countKeyToUpdate],
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId
            ? {
                ...c,
                workflowState: COMMENT_WORKFLOW_STATES.DELETED,
                deletedAt: new Date(), // TODO: will be replaced once history will be implements
              }
            : c )
      }
    }
    case DELETE_COMMENT_ANSWER_BY_ADMIN_SUCCESS: {
      const { commentId } = payload;
      const deleted = state.unverifiedComments.find(c => c._id === commentId );
      return {
        ...state,
        unhandledAnswersCount: deleted.companyAnswer.workflowState === COMMENT_WORKFLOW_STATES.NEW ? state.unhandledAnswersCount - 1 : state.unhandledAnswersCount,
        unverifiedComments: state.unverifiedComments.map(c =>
          c._id === commentId
            ? {
              ...c,
              companyAnswer: {
                ...c.companyAnswer,
                workflowState: COMMENT_WORKFLOW_STATES.DELETED,
                deletedAt: new Date(),
              },
            }
            : c )
      }
    }
    case DELETE_RATING_BY_ADMIN_SUCCESS: {
      const { ratingId } = payload;
      const deleted = state.unverifiedRatings.find(r => r._id === ratingId );
      const countKeyToUpdate = 'unhandledRatingsCount';

      return {
        ...state,
        [countKeyToUpdate]: deleted.workflowState === RATING_WORKFLOW_STATES.NEW ? state[countKeyToUpdate] - 1 : state[countKeyToUpdate],
        unverifiedRatings: state.unverifiedRatings.map(r =>
          r._id === ratingId
            ? {
              ...r,
              workflowState: RATING_WORKFLOW_STATES.DELETED,
              deletedAt: new Date(), // TODO: will be replaced once history will be implements
            }
            : r )
      }
    }

    case POST_RATING_BY_ADMIN_SUCCESS: {
      const { ratingId } = payload;
      const posted = state.unverifiedRatings.find(r => r._id === ratingId );
      const countKeyToUpdate = 'unhandledRatingsCount';

      return {
        ...state,
        [countKeyToUpdate]: posted.workflowState === RATING_WORKFLOW_STATES.NEW ? state[countKeyToUpdate] - 1 : state[countKeyToUpdate],
        unverifiedRatings: state.unverifiedRatings.map(c =>
          c._id === ratingId
            ? {
              ...c,
              workflowState: RATING_WORKFLOW_STATES.POSTED,
              postedAt: new Date() // TODO: will be replaced once history will be implements
            }
            : c )
      }
    }


    case FETCH_COMPANY_REVIEWS_ERROR:
    case CREATE_COMPANY_REVIEW_ERROR:
    case FETCH_UNVERIFIED_COMMENTS_ERROR:
    case FETCH_UNVERIFIED_RATINGS_ERROR:
    case POST_COMMENT_BY_ADMIN_ERROR:
    case DELETE_COMMENT_BY_ADMIN_ERROR:
    case EDIT_COMMENT_BY_ADMIN_ERROR:
    case CREATE_OWNER_ANSWER_ERROR:
    case POST_RATING_BY_ADMIN_ERROR:{
      return {
        ...state,
        loading: false,
        error
      }
    }

    default:
      return state;
  }

}
