import { appName } from '../../config';

export const moduleName = 'profile';
const prefix = `${appName}/${moduleName}`;

export const FETCH_PROFILE_REVIEWS_REQUEST = `${prefix}/FETCH_PROFILE_REVIEWS_REQUEST`;
export const FETCH_PROFILE_REVIEWS_INIT_SUCCESS = `${prefix}/FETCH_PROFILE_REVIEWS_INIT_SUCCESS`;
export const FETCH_PROFILE_REVIEWS_ADD_SUCCESS = `${prefix}/FETCH_PROFILE_REVIEWS_ADD_SUCCESS`;
export const FETCH_PROFILE_REVIEWS_ERROR = `${prefix}/FETCH_PROFILE_REVIEWS_ERROR`;

export const CHANGE_PASSWORD_REQUEST = `${prefix}/CHANGE_PASSWORD_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${prefix}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_ERROR = `${prefix}/CHANGE_PASSWORD_ERROR`;

export const RESET_PROFILE_DATA = `${prefix}/RESET_PROFILE_DATA`;