import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { withState } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconBtnS } from './IconButton';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getThemeColor } from '../../../utils/theme';

const SelectItemsWrap = styled.div`
  position:relative;
  cursor: pointer;
`;

const SelectItem = styled(DisplayFlexS)`
  color: ${getThemeColor(['red'])};
  padding: 10px;
  border-radius: 2px;
  ${({ isOpen }) => isOpen && `
    background: #fff;
  `};
`;

const SelectDrop = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  padding-top: 5px;
  background-color: ${getThemeColor(['white'])};
  border-top: 1px solid  ${getThemeColor(['grayBlue'])};
  ${({ isOpen }) => isOpen && `
    display: block;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  `};
`;

const SelectDropItem = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  &:hover {
    color: ${getThemeColor(['red'])};
  }
  ${({ active }) => active && `
    color: green;
  `}
`;

const SelectIcon = styled(FontAwesomeIcon)`
  color: ${getThemeColor(['red'])};
  margin-left: 8px;   
`;

const SelectItemsPerPage = ({ isDropdownOpen, setDropdownOpen, onChangeItemsPerPage, itemsPerPage, options }) => (
  <SelectItemsWrap>
    <SelectItem
      aItems="center"
      isOpen={isDropdownOpen}
      onClick={() => setDropdownOpen(!isDropdownOpen)}
    >
      {itemsPerPage} <Trans i18nKey="adminPanel.companies.companiesRender"/>
      <IconBtnS
        iconName="angle-down"
        color="red"
        sizeCircle="10px"
      />
    </SelectItem>
    <SelectDrop isOpen={isDropdownOpen}>
      {options.map(count =>
        <SelectDropItem
          key={count}
          onClick={() => {
            setDropdownOpen(false);
            onChangeItemsPerPage(count);
          }}
        >
          { count } <Trans i18nKey="adminPanel.companies.companiesRender"/>
          { count === itemsPerPage && <SelectIcon icon="check"/> }
        </SelectDropItem>
      )}
    </SelectDrop>
  </SelectItemsWrap>
);

export default withState('isDropdownOpen', 'setDropdownOpen', false)(SelectItemsPerPage);
