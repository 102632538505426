import { keys, join,  } from 'ramda';

const arrayToQueryStrings = arr => arr.join(',');

/**
*  Function to create query for url
*
*  @param
 *  url {String}
 *  queries {Object}
 *
 *  @return url {String}
* */
export default (url, queries) => `${url}?${join('&', keys(queries).map(k =>
  Array.isArray(queries[k]) ? `${k}=${arrayToQueryStrings(queries[k])}` : `${k}=${queries[k]}`))}`;
