import {
  CLEAN_UP_AUTH_ERRORS,
  DELETE_AVATAR_ACTIVE_USER_REQUEST,
  GET_ACTIVE_USER_REQUEST,
  LOGIN_REQUEST, LOGOUT_ADMIN_REQUEST,
  LOGOUT_REQUEST,
  RESET_PASS_REQUEST,
  GENERATE_PASS_REQUEST,
  RESOLVE_REGISTRATION_CONFLICT_REQUEST,
  SIGN_UP_REQUEST,
  UPDATE_ACTIVE_USER_REQUEST,
  UPDATE_AVATAR_ACTIVE_USER_REQUEST,
} from './authConstants';

export const signUp = payload => ({
    type: SIGN_UP_REQUEST,
    payload: payload.values,
    withNotification: payload.withNotification,
});

export const login = (payload, isAdmin = false) => ({
  type: LOGIN_REQUEST,
  payload,
  isAdmin
});

export const logout = payload => ({
  type: LOGOUT_REQUEST,
  payload // route location
});

export const logoutAdmin = () => ({
  type: LOGOUT_ADMIN_REQUEST,
});

export const resetPass = payload => ({
  type: RESET_PASS_REQUEST,
  payload,
});

export const resolveRegistrationConflict = payload => ({
  type: RESOLVE_REGISTRATION_CONFLICT_REQUEST,
  payload,
});

export const getActiveUser = () => ({
  type: GET_ACTIVE_USER_REQUEST
});

export const updateActiveUser = payload => ({
  type: UPDATE_ACTIVE_USER_REQUEST,
  payload,
});

export const updateActiveUserAvatar = payload => ({
  type: UPDATE_AVATAR_ACTIVE_USER_REQUEST,
  payload
});

export const deleteActiveUserAvatar = payload => ({
  type: DELETE_AVATAR_ACTIVE_USER_REQUEST,
  payload
});

export const cleanUpAuthErrors = () => ({
  type: CLEAN_UP_AUTH_ERRORS
});

export const generatePass = (payload) => ({
  type: GENERATE_PASS_REQUEST,
  payload
})
