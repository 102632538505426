import styled from 'styled-components';
import { getThemeColor, getThemeSize } from '../../../../utils/theme';

export const WhiteCardS = styled.div`
  display: inline-block;
  background: ${getThemeColor(['white'])};
  padding: 50px 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);;
`;

export const PageTitleVioletS = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  font-size: ${getThemeSize(['headers', 'companyItemName'])};
  font-weight: normal;
  text-transform: uppercase;
  color: ${getThemeColor(['darkerViolet'])};
`;

export const PageTitleRedS = styled(PageTitleVioletS)`
  color: ${getThemeColor(['red'])};
`;