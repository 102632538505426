import React from 'react';
import styled from 'styled-components';
import {compose, lifecycle} from 'recompose';
import {connect} from 'react-redux';
import { push } from 'connected-react-router';

import Sidebar from './adminLayout/Sidebar/SidebarComponent';
import Header from './adminLayout/Header/HeaderComponent';
import { NotificationBar } from '../common';

import { routes } from '../../routes/routesConfig';
import { reviewActions } from '../../../modules/review';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { hasAccessToAdminPanel } from '../../../utils/auth';
import { isAdminTokenPresent } from '../../../utils/auth';

/*
* This is the main wrapper where should be combined all layout for admin. It will be rendered for all views.
*
* */

export const AdminLayoutWrap = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  height: 1000%;
`;

export const LayoutWrap = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 80px 65px 165px;
    height: 100%;
`;

export const MainLayoutWrap = styled(DisplayFlexS)`
  flex: 0 0 75%;
  max-width: 75%;
`;

const AdminLayout = ({ children }) => (
  <AdminLayoutWrap justify="space-between">

    <Sidebar />

    <MainLayoutWrap justify="space-between" direction="column">
      <Header />
      <LayoutWrap>{ children }</LayoutWrap>
    </MainLayoutWrap>

    <NotificationBar />
  </AdminLayoutWrap>
);

const enhancer = compose(
    connect(({ auth }) => ({ activeUser: auth.activeUser }),
      { push, fetchUnhandledCounts: reviewActions.fetchUnhandledCommentsCounts }),
    lifecycle({
        componentDidMount() {
          if(!isAdminTokenPresent()) {
              return this.props.push(`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`)
          }
          this.props.fetchUnhandledCounts();
        },
        componentDidUpdate(prevProps) {
          const { activeUser, push } = this.props;

          if (activeUser !== prevProps.activeUser && !hasAccessToAdminPanel(activeUser)) {
            push(`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`)
          }
        }
    })
);

export default enhancer(AdminLayout);
