import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from './Text';
import { getThemeColor } from '../../../utils/theme';

const IconWrapS = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${R.propOr('30px', 'sizeCircle')};
    height: ${R.propOr('30px', 'sizeCircle')};
    border-radius: 50%;
    background: ${({bgColor}) => getThemeColor([bgColor])};
    transition: all 0.3s ease-in-out;
`;

const IconS = styled(FontAwesomeIcon)`;
    color: ${({color}) => getThemeColor([color || 'white'])};
    transition: all 0.3s ease-in-out;
`;

const TextS = styled(Text)`
    margin-left: 4px;
    color: ${({color}) => getThemeColor([color || 'white'])};
`;

const IconNumberS = styled(Text)`
    color: ${({color}) => getThemeColor([color || 'primary'])};
`;

const IconBtnWrapS = styled.button`
    display: ${R.propOr('flex', 'displayBlock')};
    align-items: center;
    background: none; 
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    color: ${({color}) => getThemeColor([color || 'white'])};
    margin: ${R.propOr('', 'margin')};
    ${({active}) => !active},
    &:hover
     {
        ${IconS}, ${TextS}, ${IconNumberS} {
            color: ${({colorHover}) => getThemeColor([colorHover])};
        }   
        ${IconWrapS} {
            background-color: ${({bgColorHover}) => getThemeColor([bgColorHover])};
            &:after {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }
        }
    }
    &:disabled {
        pointer-events: none;
    }
`;

/*
* Component rendering Icon, Icon + text, number
* iconName - name FontAwesomeIcon
* number - display the number instead of the icon
* disabled - disabled element
* text - display text
* color - initial color
* colorHover - hover color
* bgColor - backgroundColor for Icon
* bgColorHover - hover backgroundColor for Icon
* sizeCircle - circle size icons, width = height default: (30px) example: (20px)
* */

export const IconBtnS = ({
  onClick,
  disabled,
  size,
  iconName,
  number,
  text,
  color,
  colorHover,
  bgColor,
  bgColorHover,
  sizeCircle,
  displayBlock,
  margin,
  active
}) => (
  <IconBtnWrapS {...{ disabled, displayBlock, bgColorHover, color, colorHover, margin, active, onClick }}>
    <IconWrapS {...{ bgColor, sizeCircle }}>
      {
        iconName
          ? <IconS icon={ iconName } {...{ color, size } } />
          : <IconNumberS size="normalText">{number}</IconNumberS>
      }
    </IconWrapS>
    {text && <TextS size="bigText" transform="uppercase" {...{ color }}>{text}</TextS>}
  </IconBtnWrapS>
);
