import { lifecycle, withState } from 'recompose';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { authActions } from '../../../../../modules/auth';
import { fetchCompaniesByAdmin, fetchDeletedCompanies } from '../../../../../modules/company/companyActions';

const { logoutAdmin } = authActions;

const HeaderContainer = compose(
    connect(({ router }) => ({ location: router.location }),
      { logoutAdmin, fetchCompaniesByAdmin, fetchDeletedCompanies, push }),
    withState('isOpen', 'setIsOpen', false),
    lifecycle ({
        componentDidUpdate(prevProps) {
            if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
                document.addEventListener('click', () => {
                    this.props.setIsOpen(false)
                }, {
                    once: true
                })
            }
        }
    })
);

export default HeaderContainer;
