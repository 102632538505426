import i18n from 'i18next';

import { de, en } from './translations';
import { getItem } from '../utils/helpers/localStorage';

export default i18n
  // .use(LanguageDetector) // i18next-browser-languagedetector package to handle automaticly language in browser
  .init({
    resources: {
      de: {
        translations: de,
      },
      en: {
        translations: en,
      },
    },
    lng: getItem('i18nLanguage') || 'de',
    lang: getItem('i18nLanguage') || 'de',
    fallbackLng: ['de', 'en'],
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      formatSeparator: '.',
    },
    react: {
      wait: true,
    },
  });
