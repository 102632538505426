import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';

import { InputLabel } from './TextInput';

export const RadioLabel = styled.label`
  position: relative;
  color: ${getThemeColor(['greyViolet'])};
  padding-left: 20px;
  margin-top: 10px;
  margin-right: 40px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-family: ${getThemeFont(['regular'])};
    font-size: ${getThemeSize(['middleSmallText'])};
  }

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid;
    border-color: ${(props) => (props.checked ? getThemeColor(['red']) : getThemeColor(['greyViolet']))};
    box-sizing: border-box;
  }

  &:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    display: ${(props) => (props.checked ? 'inline-block' : 'none')};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${getThemeColor(['red'])};
    box-sizing: border-box;
  }
`;

export const RadioBox = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

const Radio = ({ transKeyBase, radioGroupLabel, name, options, initialState, onChange }) => {
  return (
    <DisplayFlexS direction="column" directionScreenMedium="column">
      {radioGroupLabel && <InputLabel>{radioGroupLabel}</InputLabel>}
      <DisplayFlexS aItems="center" alignItemsScreenMedium="center">
        {options &&
          options.map((option, k) => (
            <RadioLabel checked={initialState === option} key={k}>
              <RadioBox
                name={name}
                value={option}
                checked={initialState === option}
                onChange={() => onChange(option)}
              />
              {t(`${transKeyBase}.${name}.${option}`)}
            </RadioLabel>
          ))}
      </DisplayFlexS>
    </DisplayFlexS>
  );
};

export default Radio;
