import { CHANGE_PASSWORD_REQUEST, FETCH_PROFILE_REVIEWS_REQUEST, RESET_PROFILE_DATA } from "./profileConstants";

export const fetchProfileReview = payload => ({
  type: FETCH_PROFILE_REVIEWS_REQUEST,
  payload,
});

export const changePassword = payload => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload, // old pass, new pass
});

export const resetProfileData = () => ({
  type: RESET_PROFILE_DATA
});

