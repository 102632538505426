import * as Yup from 'yup';
import i18next from 'i18next';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { equals } from 'ramda';

import { LoginFormComponent } from './loginFormComponent';
import { withForm } from '../../../../utils/enhancers';
import { withLanguageOnChange } from "../../../../utils/enhancers/withLanguageChange";
import { ERROR_CODES_RELATED_TO_EMAIL, ERROR_CODES_RELATED_TO_PASS } from '../../../constants/errors';

import { authActions } from '../../../../modules/auth';

export const LoginFormContainer = compose(
  connect(({ auth }) =>
      ({
        serverError: auth.loginError,
      }),
    { login: authActions.login }),
  withLanguageOnChange,
  withForm({
    mapPropsToValues: () => ({ email: '', password: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(i18next.t('forms.login.errors.email.isNotValid'))
        .required(i18next.t('forms.login.errors.email.required')),
      password: Yup.string()
        .required(i18next.t('forms.login.errors.pass.required'))
    }),
    handleSubmit: (values, { setSubmitting, resetForm, props: { login, isAdmin }, }) => {
      setSubmitting(false);
      login(values, isAdmin);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!equals(prevProps.serverError, this.props.serverError) && !!this.props.serverError) {
        const { messageCode } = this.props.serverError;

        const getFieldNameAccordingToError = () =>
          ERROR_CODES_RELATED_TO_PASS.includes(messageCode) ? 'password' :
            ERROR_CODES_RELATED_TO_EMAIL.includes(messageCode) ? 'email' : null;

        this.props.setFieldError(getFieldNameAccordingToError(), i18next.t(`general.serverErrorsByCodes.${messageCode}`))
      }
    }
  }),
)(LoginFormComponent);
