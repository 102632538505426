import { get, patch, post, putApi, remove } from './apiService';
import urls from './urls';

export {
  urls,
  get,
  post,
  putApi,
  remove,
  patch
};
