import styled from "styled-components";

import {getThemeColor} from "../../../utils/theme";

export const TabsButton = styled.button`
  margin: 10px 0px 15px;
  padding: 15px;
  
  width: 50%;
  
  background-color: ${getThemeColor(['white'])}

  border: none;
  border-bottom: 3px solid ${props => props.selected ? 
    getThemeColor(['red']) : getThemeColor([props.borderColor || 'white'])};
  
  outline: none;
  
  cursor: pointer
`;
