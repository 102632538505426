import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import i18n from '../../../i18n';
import { modalGA, socialLinkGA } from '../../../utils/GA';
import { handleLanguageSwitch } from '../languageSwitcher/languageSwitcherComponent';
import { isLoggedIn } from '../../../utils/auth';

import { routes } from '../../routes/routesConfig';
import { EXTERNAL_LINKS } from '../../constants/externalLinks';
import { socialGA } from '../../constants/googleAnalytics';
import { authModalLayouts } from '../../constants/modals';

import { LinkS, LinkTextS, Logo } from '../common';

import globeIcon from '../../assets/imgs/globe.svg';
import signInAlt from '../../assets/imgs/sign-in-alt.svg';

import { toggleModal as toggleModalAction } from '../../../modules/app/appActions';

import {
  DrawerContentS,
  CloseButtonS,
  DrawerNavigationS,
  RedirectS,
  LanguageSwitchS,
  DrawerRegistrationS,
  DrawerBottomLinksS,
  DrawerHeaderS,
} from './mobileNavS';

const menuDrawerItems = [
  {
    transKey: 'drawerMenu.blog',
    redirect: EXTERNAL_LINKS.BLOG,
    onClick: socialLinkGA(socialGA.BLOG, EXTERNAL_LINKS.BLOG),
  },
  {
    transKey: 'drawerMenu.contacts',
    linkTo: routes.CONTACTS,
  },
  {
    transKey: 'drawerMenu.forCompanies',
    redirect: `https://treuhand-suche.ch/blog/fuer-treuhaender/`,
  },
];

const MobileNav = ({ isDrawerOpen, toggleDrawer }) => {
  const dispatch = useDispatch();

  const handleItemClick = (onClick) => {
    toggleDrawer();
    if (onClick) onClick();
  };

  const renderMenuItem = ({ transKey, redirect, linkTo, onClick }) => {
    const handleClick = () => handleItemClick(onClick);

    return (
      <li key={transKey}>
        {redirect ? (
          <RedirectS href={redirect} onClick={handleClick}>
            <LinkTextS>{i18n.t(transKey)}</LinkTextS>
          </RedirectS>
        ) : (
          <LinkS margin="20px" to={linkTo || '/'} onClick={handleClick}>
            <LinkTextS>{i18n.t(transKey)}</LinkTextS>
          </LinkS>
        )}
      </li>
    );
  };

  const handleRegistrationClick = () => {
    toggleDrawer();
    if (!isLoggedIn()) {
      dispatch(toggleModalAction({ open: true, layout: authModalLayouts.login }));
      modalGA('/login');
    }
  };

  return (
    <Drawer open={isDrawerOpen} onClose={toggleDrawer} direction="left" className="drawer">
      <DrawerContentS>
        <DrawerHeaderS>
          <Logo onClick={toggleDrawer} />
          <CloseButtonS onClick={toggleDrawer} />
        </DrawerHeaderS>
        <DrawerNavigationS>
          {menuDrawerItems.map(renderMenuItem)}

          <LanguageSwitchS onClick={handleLanguageSwitch}>
            <img src={globeIcon} alt="globe" />
            {i18n.language === 'de' ? 'EN' : 'DE'}
          </LanguageSwitchS>
          <DrawerBottomLinksS>
            <LinkS to={routes.AGB} onClick={toggleDrawer}>
              <Trans i18nKey="drawerMenu.agb" />
            </LinkS>
            <LinkS to={routes.DATA_PROTECTION} onClick={toggleDrawer}>
              <Trans i18nKey="drawerMenu.dataProtection" />
            </LinkS>
          </DrawerBottomLinksS>
          {!isLoggedIn() && (
            <DrawerRegistrationS onClick={handleRegistrationClick}>
              <img src={signInAlt} alt="sign-in" />
              <Trans i18nKey="drawerMenu.registration" />
            </DrawerRegistrationS>
          )}
        </DrawerNavigationS>
      </DrawerContentS>
    </Drawer>
  );
};

export default MobileNav;
