import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HistogramContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;

  & canvas {
    padding: 0 5px;
  }
`;

export const HistogramComponent = ({dataValues, highlight}) => {
  const barData = {
    labels: dataValues.map((value, index) => index),
    datasets: [
      {
        categoryPercentage: 1,
        barPercentage: 0.5,
        backgroundColor: dataValues.map((val, index) =>
          index >= highlight[0] && index < highlight[1] ? "#C4C4C4" : "#E3E2E2"
        ),
        hoverBackgroundColor: "#C4C4C4",
        data: dataValues
      }        
    ]
  }; 
  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: { display: false },
    tooltips: { enabled: false },
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        { 
          display: false,
          ticks: {
            beginAtZero: true
          } 
        }
      ]
    }
  };

  return (
    <HistogramContainer>
      <Bar 
        data={barData} 
        options={options} 
        height={85} 
      />
    </HistogramContainer>
  )
}

HistogramComponent.propTypes = {
  dataValues: PropTypes.array.isRequired,
  highlight: PropTypes.array.isRequired
};