import * as R from 'ramda';
import styled from 'styled-components';

import { Text } from './Text'
import { getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';

const LinkTextS = styled(Text)`
  color: ${({ color }) => getThemeColor([color || 'greyViolet'])};
  font-size: ${({ size }) => getThemeSize([size || 'bigText'])};
  font-family: ${getThemeFont(['bold'])};
  
  &:hover {
    color: ${getThemeColor(['violet'])};
  };
  
  &:visited{
    color: ${R.propOr(getThemeColor(['primaryGray']), 'color')};
  };
`;

export { LinkTextS };
