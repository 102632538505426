import moment from 'moment';
import 'moment/locale/de'; // need for Germany language date

import { getItem } from "./localStorage";

export default () => {
  let languageApp = getItem('i18nLanguage');

  switch (languageApp) {
    case 'en':
      moment.locale('en');
      break;

    case 'de':
      moment.locale('de');
      break;

    default:
      moment.locale('en');
  }
}
