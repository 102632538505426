import styled from 'styled-components';

import { IconBtnS } from '../../../common/IconButton';
import { LinkS, Text } from '../../../common';

import { DisplayFlexS } from '../../../../../utils/stylesHelpers';
import { getThemeColor } from '../../../../../utils/theme';

export const Container = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: auto 0;
    padding: 0 65px;
`;

export const HeaderWrap = styled.div`
    background: #FFFFFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    width: 100%;
`;

export const HeaderSearch = styled.div``;

export const HeaderNewCompany = styled.div``;

export const HeaderLeft = styled(DisplayFlexS)`
    padding: 20px 30px 20px 0;
    border-right: 1px solid ${getThemeColor(['grayBlue'])}
`;

export const HeaderRight = styled(DisplayFlexS)`
    padding-left: 25px; 
`;

export const HeaderDropWrap = styled(DisplayFlexS)`
    position: relative;
    padding: 0 15px;
`;

export const HeaderDropIcon = styled(DisplayFlexS)`
    width: 40px;
    height: 40px;
    border: 1px solid ${getThemeColor(['violet'])};
    border-radius: 50%;
`;

export const HeaderDropArrow = styled(IconBtnS)`
    ${({ open }) => open && `
        transform: rotate(90deg);
    `}
`;

export const HeaderDropMenu = styled.div`
    position: absolute;
    top: 140%;
    left: -15px;
    background-color: ${getThemeColor(['white'])};
    min-width: 145px;
    padding: 0 15px;
    border-top: 1px solid ${getThemeColor(['grayBlue'])}
`;
export const HeaderDropProfile = styled.div`
    display: flex;
`;

export const HeaderDropMenuItem = styled(LinkS)`
    display: flex;
    align-items: center;
    padding: 10px 0;
`;

export const HeaderDropMenuText = styled(Text)`
    transition: all .3s ease-in-out;
    &:hover {
    color: ${getThemeColor(['violet'])}
`;
