import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';

export const Text = styled(
  props => (<span {...R.omit(['color', 'lineHeight', 'size', 'maxWidth', 'fontFamily'], props)} />)
)`
  ${({ margin }) => margin ? `margin: ${margin}` : ''};
  display: ${({ margin }) => margin ? 'inline-block' : 'inline'};
  max-width: ${({ maxWidth }) => maxWidth || getThemeSize(['textBlock'])};
  
  font-size: ${({ size }) => getThemeSize([size || 'normalText'])};
  font-family: ${({fontFamily}) => getThemeFont([fontFamily || 'regular'])};
  ${({ weight }) => weight ? `font-weight: ${weight}` : ''};
  color: ${({ color }) => getThemeColor([ color || 'primary'])};
  
  line-height: ${R.propOr('normal', 'lineHeight')};
  text-align: ${({ align }) => align || 'center'};
  text-transform: ${R.propOr('none', 'transform')};

  @media (max-width: ${getScreenSize(['medium'])}) {
    text-align: ${R.propOr('left', 'alignscreenmedium')};
  }
`;

