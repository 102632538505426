import React from "react";
import {lifecycle} from "recompose";
import styled from "styled-components";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Divider, LinkS, LinkTextS } from "../common";
import { DisplayFlexS } from "../../../utils/stylesHelpers";
import { getScreenSize, getThemeColor } from "../../../utils/theme";
import { getCompanyRoute } from '../../../utils/helpers/routesBuilder';

import ProfileIcon from "../../assets/imgs/icons/User_icon.svg";

const DropdownProfileMenuWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  
  height: 100%;
  min-width: 140px;
  
  cursor: pointer;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     flex-direction: column;
     align-items: flex-start;
     
     margin: 10px 20px 0;
  }
`;

const DropdownProfileHeader = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     width: 100%;
     align-items: center;
     margin-bottom: 10px;

  }
`;

const LogoWrapS = styled.div`
  margin-right: 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 40px;
  height: 40px;
  
  border: 1px solid ${getThemeColor(['greyVioletLight'])};
  border-radius: 50%;
`;

const LogoIconS = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
`

const AngleIconWrap = styled.div`
  display: flex
  justify-content: center;
  align-items: center;
  
  margin-left: 20px;
  
  height: 30px;
  width: 30px;
  
  color: ${getThemeColor(['red'])};
  border-radius: 50%;

  transition: transform .3s ease-in-out; 
  transform: ${({show}) => show ? 'rotateX(0deg)' : 'rotateX(180deg)'};
  
  &:hover{
    background-color: ${getThemeColor(['grayBlue'])};
    cursor: pointer;
  }
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-left: auto;
  }
`;

const DropdownProfileMenuS = styled.div`
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  
  min-width: 195px;  
  width: ${({ width }) =>  width ? `${width}px` : 'inherit'};
  margin: 0 auto;
  padding: 10px 0;
  
  border-radius: 3px;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0,0,0,.10);
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     position: static;
     
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     
     margin: 0 auto 0 0;
     padding: 0;
     
     box-shadow: none;
  }
`;

const DropdownProfileMenuItem = styled(DisplayFlexS)`
  padding: 10px 20px 10px 20px;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     flex-direction: row;
     margin: 10px 40px 10px 15px;
     padding: 0;
  }
`;

const IconMenuWrap = styled.div`
  display: flex
  justify-content: center;
  align-items: center;
  
  margin-right: 15px;
  
  color: ${getThemeColor(['darkViolet'])};
`;

const DropdownProfileMenu = ({
  company,
  showDropMenu,
  setDropMenu,
  logout,
  location,
  mobileVersion,
  toggleMobileMenu,
  isMobileMenuOpen,
  refDropDownMenu,
  widthDropDownMenu,
}) => (
      <DropdownProfileMenuWrap onClick={() => setDropMenu(!showDropMenu)} ref={refDropDownMenu}>
        <DropdownProfileHeader >
          <LogoWrapS>
            <LogoIconS src={company.logo || ProfileIcon} alt="Company Logo"/>
          </LogoWrapS>
          <LinkTextS color='violet'>
            {company.name ?  company.name : i18n.t('header.menu.userName')}
          </LinkTextS>
          <AngleIconWrap show={showDropMenu}>
            <FontAwesomeIcon  size="2x" icon='angle-up'/>
          </AngleIconWrap>
        </DropdownProfileHeader>
        {
          showDropMenu && (
            <DropdownProfileMenuS width={widthDropDownMenu}>
              <DisplayFlexS direction='column' directionScreenMedium='column'>
                {/*<LinkS to={routes.PROFILE}>*/}
                {/*  <DropdownProfileMenuItem*/}
                {/*    alignItemsScreenMedium='center'*/}
                {/*    aItems='center'*/}
                {/*    onClick={() => mobileVersion ? toggleMobileMenu(!isMobileMenuOpen) : null}*/}
                {/*  >*/}
                {/*    <IconMenuWrap>*/}
                {/*      <FontAwesomeIcon icon='user'/>*/}
                {/*    </IconMenuWrap>*/}
                {/*    <LinkTextS color='primary' size='normalText'>*/}
                {/*      {i18n.t('header.menu.myProfile')}*/}
                {/*    </LinkTextS>*/}
                {/*  </DropdownProfileMenuItem>*/}
                {/*</LinkS>*/}
                {
                  company.base &&
                  <LinkS to={getCompanyRoute(company._id, company.name)}>
                    <DropdownProfileMenuItem
                      alignItemsScreenMedium='center'
                      aItems='center'
                      onClick={() => mobileVersion ? toggleMobileMenu(!isMobileMenuOpen) : null}
                    >
                      <IconMenuWrap>
                        <FontAwesomeIcon icon='briefcase'/>
                      </IconMenuWrap>
                      <LinkTextS color='primary' size='normalText'>
                        {i18n.t('header.menu.myCompany')}
                      </LinkTextS>
                    </DropdownProfileMenuItem>
                  </LinkS>
                }
                {
                  !mobileVersion &&  <Divider/>
                }
                <DropdownProfileMenuItem
                  alignItemsScreenMedium='center'
                  aItems='center'
                  onClick={
                    () => {
                      if (mobileVersion) toggleMobileMenu(!isMobileMenuOpen);
                      logout(location);
                    }
                  }
                >
                  <IconMenuWrap>
                    <FontAwesomeIcon icon='sign-out-alt'/>
                  </IconMenuWrap>
                  <LinkTextS color='primary' size='normalText'>
                    {i18n.t('header.menu.logout')}
                  </LinkTextS>
                </DropdownProfileMenuItem>
              </DisplayFlexS>
            </DropdownProfileMenuS>
          )
        }
      </DropdownProfileMenuWrap>
);

export const DropdownProfileMenuContainer = lifecycle ({
  componentDidUpdate(prevProps) {
    if (prevProps.showDropMenu !== this.props.showDropMenu && this.props.showDropMenu) {
      document.addEventListener('click', () => {
        this.props.setDropMenu(false)
      }, {
        once: true
      })
    }
  }
})(DropdownProfileMenu)
