import i18next from 'i18next';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { getScreenSize } from '../../../../utils/theme';
import { PrimaryButtonS, TextInput, FieldError } from '../../common';

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 5px;
  min-height: 70px;
`;

const PrimaryButtonResponsiveS = styled(PrimaryButtonS)`
  margin: 25px 0;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 14px 0;
    }
`;

export const LoginFormComponent = ({
  values,
  errors,
  touched,
  onSubmit,
  onChange,
  onBlur,
  isSubmitting,
  serverError,
  isAdmin
}) => (
  <Form {...{ onSubmit }}>

    {serverError && [1000].includes(serverError.messageCode) && <FieldError error={i18next.t(`general.serverErrorsByCodes.${serverError.messageCode}`)} />}

    <InputWrapper>
      <TextInput
        label={i18next.t('forms.login.labels.email').toUpperCase()}
        name="email"
        type="text"
        placeholder={i18next.t('forms.login.placeholders.email')}
        value={R.prop('email', values)}
        {...{ onChange, onBlur }}
        isError={R.prop('email', errors) && R.prop('email', touched)}
        error={errors.email}
      />
    </InputWrapper>

    <InputWrapper>
      <TextInput
        label={i18next.t('forms.login.labels.pass').toUpperCase()}
        name="password"
        type="password"
        placeholder={i18next.t('forms.login.placeholders.pass')}
        value={R.prop('password', values)}
        {...{ onChange, onBlur }}
        isError={R.prop('password', errors) && R.prop('password', touched)}
        error={errors.password}
      />
    </InputWrapper>

    <PrimaryButtonResponsiveS
      disabled={isSubmitting}
      type="submit"
    >
        {
            isAdmin
                ? i18next.t('modals.auth.registration.singInBtn').toUpperCase()
                : i18next.t('forms.login.submitBtn').toUpperCase()
        }

    </PrimaryButtonResponsiveS>
  </Form>
);
