import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as R from 'ramda';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getScreenSize, getThemeColor, getThemeSize } from '../../../utils/theme';
import selectArrow from '../../assets/imgs/icons/selectArrowIcon.svg'

import { FieldError } from './Error';
import { InputLabel } from './TextInput';

const SelectFieldS = styled.select`
  width: ${R.propOr('100%', 'width')};
  flex-basis: ${({ closeBtnHandler }) => closeBtnHandler ? '95%' : '100%'};
  font-size: ${getThemeSize(['normalText'])};
  color: ${getThemeColor(['darkerViolet'])};
  padding: 8px 24px 8px 12px;
  height: ${R.propOr('50px', 'height')};
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: ${getThemeColor(['white'])} url(${selectArrow}) no-repeat 97% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  &::-ms-expand {
    /* Good browsers */
    display: none;
    opacity:0;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* IE 5-7 */
    filter: alpha(opacity=0);
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${getThemeSize(['middleSmallText'])};
    padding: 8px 14px 8px 10px;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${getThemeColor(['red'])};
  
  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-left: 10px;
  }
`;


export const SelectS = ({ labelColor, label, error, name, isError, closeBtnHandler, width, ...rest }) => (
  <Fragment>
    {
      label ? (<InputLabel labelColor={labelColor} htmlFor={name}>{label}</InputLabel>) : null
    }
    <DisplayFlexS justify="space-between" alignItemsScreenMedium="center" aItems="center" basis={width} >
      <SelectFieldS {...{ name, isError, closeBtnHandler }} {...rest} />
      {
        closeBtnHandler ?  <DeleteIcon onClick={closeBtnHandler} icon='times'/> : null
      }
    </DisplayFlexS>
    {(isError && error) ? <FieldError {...{ error }} /> : null}
  </Fragment>
);
