import * as R from 'ramda';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getThemeColor } from '../../../utils/theme';

const LinkS = styled(Link)`
  margin: ${R.prop('margin')};
  
  cursor: pointer;
  
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  };
  
  &:visited{
    color: ${R.propOr(getThemeColor(['primaryGray']), 'color')};
  };
`;

export {LinkS};
