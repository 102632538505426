import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { MODAL_TYPES } from '../../../../modules/modals/modalsConstants';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../../utils/theme';
import { GreyBtnS, PrimaryButtonS, Text } from '../../common';
import { StyledModal } from '../modalCommonS';

export const ModalTitle = styled(Text)`
  color: ${getThemeColor(['violet'])};
  font-size: ${getThemeSize(['headers', 'companyItemName'])};
  text-align: center;
  margin-bottom: 50px;
`;

export const StyledModalConfirmation = styled(StyledModal)`
  padding: 30px 10px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 303px;
  }
`;

export const BasicLayoutWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & button:first-of-type {
    margin-right: 30px;
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    & span {
      font-family: ${getThemeFont(['regular'])};
      font-size: ${getThemeSize(['mediumText'])};
      font-weight: 700;
      line-height: 15.6px;
      text-align: center;
      margin-bottom: 32px;
      display: block;
      margin-top: 36px;
      color: ${getThemeColor(['violet'])};
    }
    & button {
      padding: 10px;
      text-align: center;
      font-family: ${getThemeFont(['regular'])};
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      min-width: 107px;
    }
    & button:first-of-type {
      margin-right: 42px;
      color: ${getThemeColor(['primary'])};
    }
  }
`;

const BasicLayout = (setModalStateFor, modalName, onApproved, onRejected, entityId, otherModalProps) => (
  <BasicLayoutWrapperS>
    <ModalTitle weight="bold">
      <Trans i18nKey={`adminPanel.modals.${modalName}.text`} />
    </ModalTitle>

    <DisplayFlexS aItems="center" justify="space-between">
      <GreyBtnS
        onClick={() => {
          setModalStateFor(modalName, { open: false });
          if (onRejected) {
            onRejected(entityId, otherModalProps);
          }
        }}
        padding="10px 50px"
      >
        <Trans i18nKey={`adminPanel.modals.${modalName}.cancelBtn`} />
      </GreyBtnS>
      <PrimaryButtonS
        padding="10px 50px"
        onClick={() => {
          onApproved(entityId, otherModalProps);
          setModalStateFor(modalName, { open: false });
        }}
      >
        <Trans i18nKey={`adminPanel.modals.${modalName}.approveBtn`} />
      </PrimaryButtonS>
    </DisplayFlexS>
  </BasicLayoutWrapperS>
);

export default {
  [MODAL_TYPES.POST_COMMENT_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.DELETE_COMMENT_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.COMPANY_DELETE_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.DELETE_RATING_CONFIRMATION]: BasicLayout,
  [MODAL_TYPES.DELETE_PICTURE_COMPANY]: BasicLayout,
  [MODAL_TYPES.SAVE_CHANGES_COMPANY_PROFILE]: BasicLayout,
};
