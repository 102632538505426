import {
  APPLY_FILTERS,
  MAKE_SEARCH,
  RESET_FILTERS_REQUEST,
  SET_ALL_FILTER_VALUE,
  SET_FILTER_VALUE,
  SET_SEARCH_STRING,
  SET_SELECT_OPTION,
  SET_SORT_VALUE,
  SET_ZIP_FILTER_VALUE,
  SYNC_SELECTS_WITH_FILTERS,
  SET_ITEMS_PER_PAGE_CUSTOMER,
  SET_PAGE,
  SET_INFINITY_SCROLL,
} from './companyFilterConstants';

export const setSort = (payload) => ({
  type: SET_SORT_VALUE,
  payload // just name of sort
});

export const setFilter = ({ filterName, value }) => ({
  type: SET_FILTER_VALUE,
  payload: { filterName, value }
});

/*Have to add specific case for handling zip codes as it have diff logic*/
export const setZipFilter = (value) => ({
  type: SET_ZIP_FILTER_VALUE,
  payload: { value }
});


/* Pass data like { filterName: value, filterName2: value } */
export const setSelectOption = payload => ({
  type: SET_SELECT_OPTION,
  payload
});

// use this action in one specific case if user set All option in filter.
export const setAllOption = ({ filterName, values }) => ({ // values - array of values which will be setted
  type: SET_ALL_FILTER_VALUE,
  payload: { filterName, values }
});

/*
* Use it for apply all filter from modal for now
* */
export const applyFilters = payload => ({
  type: APPLY_FILTERS,
  payload
});

export const resetFilters = () => ({
  type: RESET_FILTERS_REQUEST
});

export const syncSelectsWithFilters = () => ({
  type: SYNC_SELECTS_WITH_FILTERS,
});

/*Use in component*/
export const makeSearch = searchStr => ({
  type: MAKE_SEARCH,
  payload: searchStr
});

/*Used internally in saga*/
export const setSearchValue = searchStr => ({
  type: SET_SEARCH_STRING,
  payload: searchStr
});

export const setItemsPerPage = (payload) => ({
  type: SET_ITEMS_PER_PAGE_CUSTOMER,
  payload
});

export const setPage = (payload) => ({
  type: SET_PAGE,
  payload
});

export const setInfinityScroll = (payload) => ({
  type: SET_INFINITY_SCROLL,
  payload
});