import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { toggleModal } from '../../../../modules/app/appActions';
import { modalGA } from '../../../../utils/GA';
import { getCompanyRoute } from '../../../../utils/helpers/routesBuilder';
import { getThemeColor, getThemeSize, getScreenSize } from '../../../../utils/theme';
import AdvertismentPlaceholderBig from '../../../assets/imgs/AdvertismentPlaceholder_big.png';
import BackgroundAuthModal from '../../../assets/imgs/BackgroundAuthModal.svg';
import LogoImg from '../../../assets/imgs/Logo.svg';
import { authModalLayouts } from '../../../constants/modals';
import { routes } from '../../../routes/routesConfig';
import { LinkS, SecondaryBtnS, Text, AdvertismentBanner } from '../../common';
import EmailForm from '../../forms/common/emailForm';
import LoginForm from '../../forms/LoginForm';
import RegistrationForm from '../../forms/RegistrationForm';
import DuplicationCompanyForm from '../../forms/RegistrationForm/duplicationCompanyForm';

const ModalWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
`;

const ModalTopContentS = styled.div`
  width: 100%;
  text-align: center;

  @media (max-width: ${getScreenSize(['medium'])}) {
    padding:  0 44px;
    height: ${({ height }) => height ? height : '100%'};
    margin-bottom: 25px;
  }
`;

const TextBtnS = styled(Text)`
  border-bottom: ${({ isActive }) => isActive ? '1px solid black' : 'none'};
  color: ${getThemeColor(['greyViolet'])};
  
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const GrayPlaceholderS = styled.div`
  background-color: ${getThemeColor(['brightVioletLight'])};
  padding: 30px;
  width: 117%;
  margin-bottom: ${({ ignoreParentPadding }) => ignoreParentPadding && '-30px'}; // padding for parent

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
    justify-content: flex-start;
    padding: 32px 0 0;
    padding: ${({ paddingTop }) => paddingTop && '50px'};
    margin-top: auto;
    margin-bottom: 0;
    height: ${({ registrationHeight }) => registrationHeight ? '26%' : '32%'};
  }
`;

const AuthGrayPlaceholderMobileS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${getScreenSize(['medium'])}) { 
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    
    background: url(${BackgroundAuthModal});
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 0 44px;
    margin-bottom: 20px;
  }
`;

const AuthFooterPlaceholderSentEmailS = styled.div`

    @media (max-width: ${getScreenSize(['medium'])}) { 
    width: 100%;
    height: 22%;
  }
`;

const AuthFooterPlaceholderMobileS = styled.div`

    @media (max-width: ${getScreenSize(['medium'])}) { 
    width: 100%;
    height: 100%;
    
    background: url(${BackgroundAuthModal});
    background-position: bottom;
    background-repeat: no-repeat;
  }
`;

const LogoS = styled.img`
   display: none;

  @media (max-width: ${getScreenSize(['medium'])}) {
    display: block;
    align-self: start;
    margin-top: 18px;
    margin-left: -24px;
    height: 24px;
    width: 157px;
   }
  
`;

const AuthHeaderTextS = styled.h2`
  font-size: ${getThemeSize(['headers', 'authModal'])};
  color: ${getThemeColor(['darkerViolet'])};
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 36px;
    color: ${getThemeColor(['violet'])};
  }
`;

const AuthHeaderTextResponsiveS = styled(AuthHeaderTextS)`

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${getThemeSize(['headers', 'authModalMobile'])};
    line-height: 33px;
    color: ${getThemeColor(['violet'])};
  }
`;

const AuthHeaderTextLoginS = styled(AuthHeaderTextResponsiveS)`
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 122px 0 36px;
  }
`;

const AuthHeaderTextRegistrationS = styled(AuthHeaderTextResponsiveS)`
  margin-bottom: 25px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 40px 0 30px !important;
  }
`;

const AuthHeaderTextResetPassS = styled(AuthHeaderTextResponsiveS)`
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 122px 0 50px;
  }
`;

const AuthHeaderTextSendEmailS = styled(AuthHeaderTextResponsiveS)`
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 78px 0 50px;
  }
`;

const AuthTextS = styled.p`
  font-size: ${getThemeSize(['normalText'])};
  color: ${getThemeColor(['primary'])};
  margin-top: 0;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 16px;

    font-size: ${getThemeSize(['bigText'])};
    text-align: center;
  }
`;


const AgreeTextForRegistration = styled(Text)`
  display: inline-block;
  margin: 22px 0 25px;
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['tagSize'])};
  max-width: 80%;

  @media (max-width: ${getScreenSize(['medium'])}) {
    min-width: 230px;
    margin: 12px 0;
    font-size: ${getThemeSize(['smallText'])};
    text-align: center;
  }
`;

const TextResponsiveS = styled(Text)`
  display: inline-block;
  margin-bottom: 23px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 28px;

    font-size: ${getThemeSize(['bigText'])};
    text-align: center;
  }

`;

const LinkResponsiveS = styled(LinkS)`

  @media (max-width: ${getScreenSize(['medium'])}) {
    color: ${getThemeColor(['darkerViolet'])};
    font-size: ${getThemeSize(['smallText'])};
  }
`;

const DuplicationCompanyWrS = styled.div`
  display: flex;
  align-items:flex-start;
  flex-direction: column;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
`

const DuplicationCompanyIconS = styled(FontAwesomeIcon)`
  font-size: ${getThemeSize(['largeText'])};
  color: ${getThemeColor(['violet'])};
  transition: all .3s ease-in-out;
`

const DuplicationCompanyS = styled.a`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width: 100%;
  padding: 20px 15px;
  font-size: ${getThemeSize(['largeText'])};
  color: ${getThemeColor(['violet'])};
  text-transform: uppercase;
  margin-bottom: 15px;
  transition: all .3s ease-in-out;
  &:hover {
    background-color: ${getThemeColor(['violet'])};
    color: ${getThemeColor(['white'])};
    text-decoration: none;
    ${DuplicationCompanyIconS} {
      color: ${getThemeColor(['white'])};
    }
  }
`;

const AdvertismentBlockS = styled.div`
    width: 117%;
    margin-bottom: -32px;

    @media (max-width: ${getScreenSize(['medium'])}) {
      display: none;
    }
`;

const RegistrationLayout = ({ setModalStateTo }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleModal({ open: false, layout: null }));
  };

  return(
    <ModalWrapperS>
      <ModalTopContentS height="78%">
      <LogoS src={LogoImg} alt='logo' />

        <AuthHeaderTextRegistrationS>
          <Trans i18nKey="modals.auth.registration.header" />
        </AuthHeaderTextRegistrationS>

        <RegistrationForm />

        <AgreeTextForRegistration>
          <Trans i18nKey="modals.auth.registration.bottomCapture.text" />
          <LinkResponsiveS to={routes.AGB} onClick={handleClick}>
          <Trans i18nKey="modals.auth.registration.bottomCapture.privacyBtn" />
          </LinkResponsiveS>
          <Trans i18nKey="modals.auth.registration.bottomCapture.and" />
          <LinkResponsiveS to={routes.DATA_PROTECTION} onClick={handleClick}>
            <Trans i18nKey="modals.auth.registration.bottomCapture.termsBtn" />
          </LinkResponsiveS>

        </AgreeTextForRegistration>
      </ModalTopContentS>

        <GrayPlaceholderS ignoreParentPadding registrationHeight>
          <AuthGrayPlaceholderMobileS>
            <AuthHeaderTextS>
              <Trans i18nKey="modals.auth.registration.backToLoginMsg" />
            </AuthHeaderTextS>
            <SecondaryBtnS onClick={() => setModalStateTo({ layout: authModalLayouts.login }) && modalGA('/login')}>
              <Trans i18nKey="modals.auth.registration.singInBtn" />
            </SecondaryBtnS>
          </AuthGrayPlaceholderMobileS>
        </GrayPlaceholderS>
    </ModalWrapperS>
)};

const LoginLayout = ({ setModalStateTo }) => <>
  <ModalWrapperS>
    <ModalTopContentS style={{ marginBottom: '25px' }} height="69%">
      <LogoS src={LogoImg} alt='logo' /> 

      <AuthHeaderTextLoginS>
        <Trans i18nKey="modals.auth.login.header" />
      </AuthHeaderTextLoginS>

      <LoginForm />

      <TextBtnS  onClick={() => setModalStateTo({ layout: authModalLayouts.resetPass })}>
        <Trans i18nKey="modals.auth.login.forgotPass" />
      </TextBtnS>
    </ModalTopContentS>

    
    <GrayPlaceholderS>
      <AuthGrayPlaceholderMobileS>
        <AuthHeaderTextS>
          <Trans i18nKey="modals.auth.login.noSignedMsg" />
        </AuthHeaderTextS>
        <SecondaryBtnS 
          onClick={() => setModalStateTo({ layout: authModalLayouts.registration }) && modalGA('/registration')}>
          <Trans i18nKey="modals.auth.login.singUpBtn" />
        </SecondaryBtnS>
      </AuthGrayPlaceholderMobileS>
    </GrayPlaceholderS>
    

    <AdvertismentBlockS>
      <AdvertismentBanner bannerUrl={AdvertismentPlaceholderBig} />
    </AdvertismentBlockS>
  </ModalWrapperS>
</>;

const ResetPass = ({ resetPass, auth }) => (
  <ModalWrapperS>
    <ModalTopContentS>
    
      <LogoS src={LogoImg} alt='logo' />

      <AuthHeaderTextResetPassS>
          <Trans i18nKey="modals.auth.resetPass.header" />
      </AuthHeaderTextResetPassS>
      <TextResponsiveS>
        <Trans i18nKey="modals.auth.resetPass.capture" />
      </TextResponsiveS>

      <EmailForm submitHandler={resetPass} serverError={auth.resetError} />
    
    </ModalTopContentS>

    <AuthFooterPlaceholderMobileS/>
  </ModalWrapperS>
);

const SendEmail = ({ auth, resolveRegistrationConflict }) => (
  <ModalWrapperS>
    <ModalTopContentS height="78%">
      <LogoS src={LogoImg} alt='logo' />

      <AuthHeaderTextSendEmailS>
        <Trans i18nKey="modals.auth.sendMail.header" />
      </AuthHeaderTextSendEmailS>

      <AuthTextS> <Trans i18nKey="modals.auth.sendMail.capture" /> </AuthTextS>
      <DuplicationCompanyWrS>
        {
          auth.registrationError && auth.registrationError.data.companies.map((c) => (
            <DuplicationCompanyS
              key={c._id}
              href={getCompanyRoute(c._id, c.name)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {c.name}
              <DuplicationCompanyIconS icon="angle-right" />
            </DuplicationCompanyS>
            )
          )
        }
      </DuplicationCompanyWrS>

      <AuthTextS> <Trans i18nKey="modals.auth.sendMail.desc" /> </AuthTextS>

      <DuplicationCompanyForm submitHandler={resolveRegistrationConflict} serverError={auth.emailError} />
    </ModalTopContentS>

    <AuthFooterPlaceholderSentEmailS>
      <AuthFooterPlaceholderMobileS style={{ marginTop: '20px' }}/>
    </AuthFooterPlaceholderSentEmailS>
  </ModalWrapperS>
);

export {
  RegistrationLayout,
  LoginLayout,
  ResetPass,
  SendEmail
}
