import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { companyFilterActions } from '../../../../modules/companyFilter';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { getThemeColor, getThemeSize, getThemeFont } from '../../../../utils/theme';
import { Divider } from '../index';

const RangeInputWrapperS = styled.div`
  width: 100%;
  height: 40px;
  max-width: ${({ isHomeFilter }) => (isHomeFilter ? '174px' : '110px')};
  border-radius: 2px;
  border: 0.5px solid ${getThemeColor(['violet'])};
  padding: 0 6px 6px;
`;

const RangeInputLabelS = styled.label`
  font-size: ${getThemeSize(['extraSmallText'])};
  line-height: ${getThemeSize(['extraSmallText'])};
  color: ${getThemeColor(['greyBlueDarkAlpha'])};
`;

const CurrencyTextS = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['smallText'])};
  color: ${getThemeColor(['violet'])};
  pointer-events: none;
`;

const RangeInputS = styled.input`
  width: 100%;
  outline: none;
  padding: 0 0 0 28px;
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['smallText'])};
  color: ${getThemeColor(['violet'])};
  border: none;
  background-color: transparent;
`;

const SeparatorS = styled(Divider)`
  width: ${({ isHomeFilter }) => (isHomeFilter ? '12px' : '3px')};
  height: 1px;
  border-top: 1px ${({ isHomeFilter }) => (isHomeFilter ? 'dashed' : 'solid')} ${getThemeColor(['violet'])};
  margin: ${({ isHomeFilter }) => (isHomeFilter ? '0 15px' : '0 4px')};
`;
const { setFilter: setReduxFilter } = companyFilterActions;

export const RangeInputs = ({
  inputRange,
  setInputData,
  setRangeFromInput,
  currency,
  homeFilter,
  names,
  label,
  filterName,
  setFilter,
}) => {
  const dispatch = useDispatch();
  const [defaultMin, defaultMax] = inputRange;

  const handleChange = (e) => {
    const thisValue = Number(e.target.value);
    const thisName = e.target.name;

    if (!isNaN(thisValue) && thisName === names[0]) {
      setInputData([thisValue, inputRange[1]]);
    }

    if (!isNaN(thisValue) && thisName === names[1]) {
      setInputData([inputRange[0], thisValue]);
    }
  };

  const handleBlur = (e) => {
    const thisValue = Number(e.target.value);
    const thisName = e.target.name;
    let result = inputRange;

    if (thisName === names[0] && thisValue >= inputRange[1]) {
      result = [defaultMin, inputRange[1]];
    }

    if (thisName === names[1] && thisValue <= inputRange[0]) {
      result = [inputRange[0], defaultMax];
    }

    setRangeFromInput(result);
    setInputData(result);
  };

  useEffect(() => {
    setInputData(inputRange);

    if (homeFilter) {
      const filterData = {
        filterName: label,
        value: { [filterName]: inputRange },
      };

      if (setFilter) {
        setFilter(filterData);
      } else {
        dispatch(setReduxFilter(filterData));
      }
    }
  }, [inputRange, homeFilter, setFilter, filterName, label, dispatch]);

  return (
    <DisplayFlexS
      justify={homeFilter ? 'flex-start' : 'space-between'}
      aItems="center"
      justifyScreenMedium="center"
      alignItemsScreenMedium="center"
      style={{ marginTop: '22px' }}
    >
      <RangeInputWrapperS isHomeFilter={homeFilter}>
        <RangeInputLabelS htmlFor={names[0]}>{i18n.t(['companies.filter.rangeInputMinLabel'])}</RangeInputLabelS>
        <DisplayFlexS
          aItems="center"
          alignItemsScreenMedium="center"
          style={{ marginTop: '3px', position: 'relative' }}
        >
          {currency && <CurrencyTextS>{currency}</CurrencyTextS>}
          <RangeInputS name={names[0]} value={inputRange[0]} onChange={handleChange} onBlur={handleBlur} />
        </DisplayFlexS>
      </RangeInputWrapperS>

      <SeparatorS isHomeFilter={homeFilter} />

      <RangeInputWrapperS isHomeFilter={homeFilter}>
        <RangeInputLabelS htmlFor={names[1]}>{i18n.t(['companies.filter.rangeInputMaxLabel'])}</RangeInputLabelS>
        <DisplayFlexS
          aItems="center"
          alignItemsScreenMedium="center"
          style={{ marginTop: '3px', position: 'relative' }}
        >
          {currency && <CurrencyTextS>{currency}</CurrencyTextS>}
          <RangeInputS name={names[1]} value={inputRange[1]} onChange={handleChange} onBlur={handleBlur} />
        </DisplayFlexS>
      </RangeInputWrapperS>
    </DisplayFlexS>
  );
};

RangeInputs.propTypes = {
  homeFilter: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  inputRange: PropTypes.array.isRequired,
  setRangeFromInput: PropTypes.func,
  setInputData: PropTypes.func,
  currency: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
};

export default RangeInputs;
