import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const RailHotSpotS = styled.div`
  width: 100%;
  position: absolute;
  cursor: pointer;
`;

export const SliderRail = ({ getRailProps }) => (
  <RailHotSpotS {...getRailProps()} />
);

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired
};