import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getThemeSize, getThemeColor, getScreenSize } from '../../../utils/theme';

const CheckIconS = styled(FontAwesomeIcon)`
  position: absolute;
  left: -18px;
  top: 1px;
  display: inline-block;
  color: ${getThemeColor(['greyDark'])}; 
`;

const FieldClarificationWrapS = styled.div`
  min-height: 50px;
  width: 100%;
  position: relative;
  
  background: ${props => props.isActive ? getThemeColor(['lightBlue']) : getThemeColor(['greyBackground'])};
  border-radius: 14px;
  margin-top: 6px;
  margin-right: ${R.propOr('0', 'marginRight')};
  padding: 12px 20px 8px 8px;
  display: block;
  columns: ${R.propOr('1', 'amountCol')};

  p {
    color: ${props => props.isActive ? getThemeColor(['primary']) : getThemeColor(['greyDark'])}; 
  }
  ${CheckIconS} {
    color: ${props => props.isActive ? getThemeColor(['green']) : getThemeColor(['greyDark'])}; 
  }

  @media (max-width: ${getScreenSize(['tabletPC'])}) {
    columns: 1;
  }
`

const FieldClarificationItemS = styled.p`
  position: relative;
  margin-top: 0px;
  margin-bottom: 8px;
  margin-left: 30px;
  font-size: ${getThemeSize(['smallText'])};
  line-height: 150%;
  color: ${getThemeColor(['greyDark'])};
`;

const FieldClarification = ({ transPath, isActive, ...rest }) => (
  <FieldClarificationWrapS {...{ ...rest }} isActive={isActive}>
    {t(transPath, { returnObjects: true }).map((comment, k) =>
      <FieldClarificationItemS key={k}>
        <CheckIconS icon="check" />
        { comment }
      </FieldClarificationItemS>
    )}
  </FieldClarificationWrapS>
);
export default FieldClarification;