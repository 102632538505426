import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { authReducer, } from '../modules/auth';
import { appReducer } from '../modules/app';
import { companyReducer } from '../modules/company';
import { companyFilterReducer } from '../modules/companyFilter';
import { reviewReducer } from '../modules/review';
import { profileReducer } from '../modules/profile';
import { modalsReducer } from '../modules/modals';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  app: appReducer,
  company: companyReducer,
  companyFilter: companyFilterReducer,
  review: reviewReducer,
  profile: profileReducer,
  modals: modalsReducer,
});
