import React from 'react';
import styled from 'styled-components';
import { Trans } from "react-i18next";

import { routes } from '../../routes/routesConfig';

import Page from '../common/hemletPageWrapper';
import { PrimaryButtonS } from '../common';
import { Text } from '../common/Text'

import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { getScreenSize, getThemeSize } from '../../../utils/theme';

import withGATracker from '../../../utils/enhancers/withGATracker';

import TopBackgroundUrl from '../../assets/imgs/Home_Top-img.svg';

const Wrapper = styled.div`
  margin-top: -60px; // header
  padding: 125px 0;
  background: url(${TopBackgroundUrl}) no-repeat;
  background-size: cover;
  
  display: flex;
  justify-content: center;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 20px auto 0 auto;
    
    display: flex;
    justify-content: center;
  }
`;

const ContentWrap = styled(DisplayFlexS)`
  width: 60%;
  flex-direction: column;
  align-items: center;  

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleText = styled(Text)`
  margin-bottom: 25px;
  font-size: ${getThemeSize(['headers', 'main'])};

  @media (max-width: ${getScreenSize(['medium'])}) {
    font-size: ${getThemeSize(['headers', 'secondary'])};
    text-align: center;
  }
`;

export default withGATracker(({ history }) => (
  <Page
    id="not-found"
    title="Not Found"
    description="This is embarrassing."
    noCrawl
  >
    <Wrapper>
      <ContentWrap>
        <TitleText color='darkerViolet'>
          <Trans i18nKey="pageNotFound.title"/>
        </TitleText>
          <PrimaryButtonS padding='10px 15px' onClick={() => history.push(routes.HOME)}>
            <Trans i18nKey="pageNotFound.button"/>
          </PrimaryButtonS>
      </ContentWrap>
    </Wrapper>
  </Page>
));
