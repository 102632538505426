import { compose, withHandlers, lifecycle, withState } from 'recompose';
import i18n from '../../i18n';

export const withLanguageOnChange = compose(
  withState('_forceUpdate', 'forceUpdate'),
  withHandlers({
    updateLanguage: ({ forceUpdate }) => () => forceUpdate(),
  }),
  lifecycle({
    componentDidMount() {
      i18n.on('languageChanged', this.props.updateLanguage);
    },
    componentWillUnmount() {
      i18n.off('languageChanged', this.props.updateLanguage);
    },
  }),
);
