/* Search functions for arrays.
* Can search by objects with deep structure
* keyPath = string in next format 'key.key1.key2....'
*  */
export default (strToSearch, options, keyPath = null) => {
  if (!options.length) {
    return options;
  }

  if (typeof options[0] === 'object' && !keyPath) {
    throw new Error('keyPath have to be present for filtering')
  }

  const safeString = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const getByPath = (object, path) => {
    if (!path) {
      return;
    }

    return path.split('.').reduce((acc, value) => acc[value], object);
  };
  const regexp = new RegExp(`^${safeString(strToSearch)}`, 'i');

  const itemsWhichMatchSearch = options.filter(l => typeof l === 'string' ? regexp.test(l) : regexp.test(getByPath(l, keyPath)));

  return itemsWhichMatchSearch;
};