import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getThemeColor } from '../../../../utils/theme';

const TrackS = styled.div`
  background-color: ${getThemeColor(['red'])};
  height: 1px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  left: ${props => props.left};
  width: ${props => props.width};
`;
const TrackHotSpotS = styled.div`
  height: 11px;
  top: ${`${11 * -0.5}px`};
  position: absolute;
  cursor: pointer;
  left: ${props => props.left};
  width: ${props => props.width};
`;

export const SliderTracks = ({ source, target, getTrackProps }) => {
  const left = `${source.percent}%`;
  const width = `${target.percent - source.percent}%`;

  return (
    <>
      <TrackS left={left} width={width} />
      <TrackHotSpotS left={left} width={width} {...getTrackProps()} />
    </>
  );
};

SliderTracks.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired
};