// The basics
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { isLoggedIn } from '../utils/auth';
import Routes from './routes';



import { authActions } from '../modules/auth';

const { getActiveUser } = authActions;

class App extends Component {
  componentDidMount() {
    if (isLoggedIn()) {
      this.props.getActiveUser()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <Routes />
  }
}

export default withRouter(connect(null, { getActiveUser })(App))
