import { SET_MODAL_STATE, MODAL_TYPES} from "./modalsConstants";

const initialState = {
  [MODAL_TYPES.COMPANY_DELETE_CONFIRMATION]: {
    open: false,
    companyId: null,
  },
  [MODAL_TYPES.POST_COMMENT_CONFIRMATION]: {
    open: false,
    id: null,
  },
  [MODAL_TYPES.DELETE_COMMENT_CONFIRMATION]: {
    open: false,
    id: null
  },
  [MODAL_TYPES.DELETE_RATING_CONFIRMATION]: {
    open: false,
    id: null
  },
  [MODAL_TYPES.DELETE_PICTURE_COMPANY]: {
    open: false,
    id: null
  },
  [MODAL_TYPES.SAVE_CHANGES_COMPANY_PROFILE]: {
    open: false,
    nextSettingsName: null
  }
};

export default function reducer (state = initialState, action) {
  const { type, payload, modalType } = action;

  switch (type) {
    case SET_MODAL_STATE: {
      return {
        ...state,
        [modalType]: payload
      }
    }
    default:
      return state
  }
}
