import styled from 'styled-components';

import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../utils/theme';
import closeSidebar from '../../assets/imgs/close-sidebar.svg';

export const DrawerContentS = styled.div`
  padding: 17px 19px 17px 21px;

  & img {
    width: 157px;
    height: 24px;
  }
`;

export const DrawerNavigationS = styled.div`
  margin-top: 52px;
  margin-left: 3px;

  & li {
    list-style: none;
    margin-bottom: 22px;

    & a {
      margin: 0;
      text-decoration: none;
    }
  }
`;

export const LanguageSwitchS = styled.div`
  margin-bottom: 29px;
  margin-top: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${getThemeFont(['regular'])};
  font-weight: 700;
  line-height: 15.6px;
  text-align: left;
  color: ${getThemeColor(['greyViolet'])};
  font-size: ${getThemeSize(['mediumText'])};

  & img {
    margin-right: 6px;
    width: 20px;
    height: 22px;
  }
`;

export const DrawerBottomLinksS = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  & a {
    margin-bottom: 8px;
  }
`;

export const DrawerRegistrationS = styled.div`
  border-top: 1px solid ${getThemeColor(['grayBlue'])};
  border-bottom: 1px solid ${getThemeColor(['grayBlue'])};
  height: 38px;
  width: 100%;
  font-family: ${getThemeFont(['regular'])};
  font-size: ${getThemeSize(['mediumText'])};
  font-weight: 700;
  line-height: 15.6px;
  text-align: left;
  color: ${getThemeColor(['violet'])};
  display: flex;
  align-items: center;
  cursor: pointer;
  & img {
    width: 22px;
    height: 20px;
    margin-right: 10px;
  }
`;

export const CloseButtonS = styled.button`
  width: 22px;
  height: 22px;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  background: url(${closeSidebar}) no-repeat;
  background-position: center;
  padding: 4px;
`;

export const RedirectS = styled.a`
  margin: 0 26px 0 0;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 20px;
  }
`;

export const DrawerHeaderS = styled.div`
  display: flex;
  justify-content: space-between;
`;
