import { appName } from '../../config';

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

// Const
export const SIGN_UP_REQUEST = `${prefix}/SIGN_UP_REQUEST`;
export const SIGN_UP_SUCCESS = `${prefix}/SING_UP_SUCCESS`;
export const SIGN_UP_ERROR = `${prefix}/SIGN_UP_ERROR`;

export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${prefix}/LOGIN_ERROR`;

export const LOGOUT_REQUEST = `${prefix}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${prefix}/LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `${prefix}/LOGOUT_ERROR`;
export const LOGOUT_ADMIN_REQUEST = `${prefix}/LOGOUT_ADMIN_REQUEST`;
export const LOGOUT_ADMIN_SUCCESS = `${prefix}/LOGOUT_ADMIN_SUCCESS`;

export const RESET_PASS_REQUEST = `${prefix}/RESET_PASS_REQUEST`;
export const RESET_PASS_SUCCESS = `${prefix}/RESET_PASS_SUCCESS`;

export const GENERATE_PASS_REQUEST = `${prefix}/GENERATE_PASS_REQUEST`;
export const GENERATE_PASS_SUCCESS = `${prefix}/GENERATE_PASS_SUCCESS`;
export const GENERATE_PASS_ERROR = `${prefix}/GENERATE_PASS_ERROR`;

export const RESET_PASS_ERROR = `${prefix}/RESET_PASS_ERROR`;

export const RESOLVE_REGISTRATION_CONFLICT_REQUEST = `${prefix}/RESOLVE_REGISTRATION_CONFLICT_REQUEST`;
export const RESOLVE_REGISTRATION_CONFLICT_SUCCESS = `${prefix}/RESOLVE_REGISTRATION_CONFLICT_SUCCESS`;
export const RESOLVE_REGISTRATION_CONFLICT_ERROR = `${prefix}/RESOLVE_REGISTRATION_CONFLICT_ERROR`;

export const GET_ACTIVE_USER_REQUEST = `${prefix}/GET_ACTIVE_USER_REQUEST`;
export const GET_ACTIVE_USER_SUCCESS = `${prefix}/GET_ACTIVE_USER_SUCCESS`;
export const GET_ACTIVE_USER_ERROR = `${prefix}/GET_ACTIVE_USER_ERROR`;

export const UPDATE_ACTIVE_USER_REQUEST = `${prefix}/UPDATE_ACTIVE_USER_REQUEST`;
export const UPDATE_ACTIVE_USER_SUCCESS = `${prefix}/UPDATE_ACTIVE_USER_SUCCESS`;
export const UPDATE_ACTIVE_USER_ERROR = `${prefix}/UPDATE_ACTIVE_USER_ERROR`;

export const UPDATE_AVATAR_ACTIVE_USER_REQUEST = `${prefix}/UPDATE_AVATAR_ACTIVE_USER_REQUEST`;
export const UPDATE_AVATAR_ACTIVE_USER_SUCCESS = `${prefix}/UPDATE_AVATAR_ACTIVE_USER_SUCCESS`;
export const UPDATE_AVATAR_ACTIVE_USER_ERROR = `${prefix}/UPDATE_AVATAR_ACTIVE_USER_ERROR`;

export const DELETE_AVATAR_ACTIVE_USER_REQUEST = `${prefix}/DELETE_AVATAR_ACTIVE_USER_REQUEST`;

export const UPDATE_ACTIVE_USER_COMPANY_SUCCESS = `${prefix}/UPDATE_ACTIVE_USER_COMPANY_SUCCESS`;

export const CLEAN_UP_AUTH_ERRORS = `${prefix}/CLEAN_UP_AITH_ERRORS`;
