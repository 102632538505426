import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { toggleModal as toggleModalAction } from '../../../modules/app/appActions';
import { logout as logoutAction } from '../../../modules/auth/authActions';
import { makeSearch as makeSearchAction } from '../../../modules/companyFilter/companyFilterActions';
import { isLoggedIn } from '../../../utils/auth';
import { modalGA, socialLinkGA } from '../../../utils/GA';
import useOnChangeLanguage from '../../../utils/hooks/useOnChangeLanguage';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import closeCircle from '../../assets/imgs/icons/closeCircle.svg';
import { EXTERNAL_LINKS } from '../../constants/externalLinks';
import { socialGA } from '../../constants/googleAnalytics';
import { authModalLayouts } from '../../constants/modals';
import { SCREEN_SIZES } from '../../constants/screenSizes';
import { routes } from '../../routes/routesConfig';
import { LinkTextS } from '../common';
import DropdownProfileMenu from '../dropdownProfileMenu';
import LanguageSwitcher from '../languageSwitcher';
import { AuthModal } from '../modals';

import {
  BurgerIconS,
  HeaderWrapperS,
  LogoHeaderS,
  MenuS,
  PrimaryButtonStyle,
  SearchInputS,
  SearchInputWrapS,
  RedirectS,
  SearchIconS,
  UserIconS,
  MobileSearchWrapperS,
  SearchWrapperS,
  SearchMobileInputS,
  EraseIcon,
  SearchBtnS,
  CompanyTitleS,
  HeaderContentS,
} from './headerS';
import MobileNav from './mobileNav';

const menuItems = [
  {
    transKey: 'header.menu.forCompanies',
    redirect: `https://treuhand-suche.ch/blog/fuer-treuhaender/`,
  },
  {
    transKey: 'header.menu.blog',
    redirect: `${EXTERNAL_LINKS.BLOG}`,
    onClick: socialLinkGA(socialGA.BLOG, `${EXTERNAL_LINKS.BLOG}`),
  },
  {
    transKey: 'header.menu.forum',
    redirect: `${EXTERNAL_LINKS.FORUM}`,
  },
  {
    transKey: 'header.menu.loginForCompanies',
    onClick: () =>
      !isLoggedIn() && toggleModalAction({ open: true, layout: authModalLayouts.login }) && modalGA('/login'),
    variableComponent: true,
  },
];

export const SearchInput = (props) => (
  <SearchInputWrapS {...props}>
    <SearchInputS {...props} />
    <FontAwesomeIcon
      icon="search"
      onClick={() => {
        if (props.mobileInput) {
          props.push(routes.COMPANIES);
          props.toggleMobileMenu(!props.isMobileMenuOpen);
        }
      }}
    />
  </SearchInputWrapS>
);

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  useOnChangeLanguage();
  const { width: windowWidth } = useWindowSize();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showDropMenu, setDropMenu] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchInputMobile, setSearchInputMobile] = useState('');

  const company = useSelector((state) => state.auth.activeUser);
  const searchInput = useSelector((state) => state.companyFilter.search);
  const companies = useSelector((state) => state.company.list);

  useEffect(() => {
    if (location.pathname !== routes.COMPANIES) {
      dispatch(makeSearchAction(''));
    }
  }, [location.pathname, dispatch]);

  const handleMenuToggle = () => setMobileMenuOpen((prevState) => !prevState);
  const toggleDrawer = () => setIsDrawerOpen((prevState) => !prevState);
  const toggleShowSearch = () => setIsSearchInputOpen((prevState) => !prevState);

  const handleSearchChange = (value) => {
    dispatch(makeSearchAction(value));
    if (location.pathname !== routes.COMPANIES) {
      history.push(routes.COMPANIES);
    }
  };

  const handleSearchTrustee = () => {
    toggleShowSearch();
    if (location.pathname !== routes.COMPANIES) {
      history.push(routes.COMPANIES);
    }
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  const handleLogIn = () => {
    if (!isLoggedIn()) {
      dispatch(toggleModalAction({ open: true, layout: authModalLayouts.login })) && modalGA('/login');
    }
  };

  return (
    <HeaderWrapperS isOpen={isMobileMenuOpen}>
      <AuthModal />
      <HeaderContentS>
        <DisplayFlexS
          justify="space-between"
          aItems="center"
          alignItemsScreenMedium="center"
          style={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <BurgerIconS
            onClick={() => {
              toggleDrawer();
            }}
          >
            <FontAwesomeIcon icon="bars" />
          </BurgerIconS>
          <SearchIconS onClick={toggleShowSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </SearchIconS>

          <MenuS>
            {menuItems.map(({ transKey, onClick, redirect }) => (
              <li key={transKey}>
                {redirect && (
                  <RedirectS href={redirect} style={{ textDecoration: 'none' }}>
                    <LinkTextS onClick={() => (onClick ? onClick() : null)}>{i18n.t(transKey)}</LinkTextS>
                  </RedirectS>
                )}
              </li>
            ))}
          </MenuS>
          {windowWidth > SCREEN_SIZES.MEDIUM ? (
            <LogoHeaderS />
          ) : (
            <DisplayFlexS justifyScreenMedium="center">
              <LogoHeaderS />
            </DisplayFlexS>
          )}
          <UserIconS
            onClick={() => {
              if (isLoggedIn()) history.push(routes.PROFILE);
              handleLogIn();
            }}
          >
            <FontAwesomeIcon icon={faUser} />
          </UserIconS>
          <MenuS>
            <DebounceInput
              minLength={1}
              debounceTimeout={300}
              value={searchInput}
              placeholder={i18n.t('header.inputPlaceholder')}
              onChange={(e) => handleSearchChange(e.target.value)}
              element={SearchInput}
            />
            <LanguageSwitcher />
            {!company ? (
              <PrimaryButtonStyle
                bgColor="violet"
                padding="7px 17px"
                transform="capitalize"
                fz="normalText"
                onClick={handleLogIn}
              >
                {i18n.t('header.menu.loginForCompanies')}
              </PrimaryButtonStyle>
            ) : (
              <DropdownProfileMenu
                company={company}
                showDropMenu={showDropMenu}
                setDropMenu={setDropMenu}
                logout={handleLogout}
                location={location}
                toggleMobileMenu={handleMenuToggle}
                isMobileMenuOpen={isMobileMenuOpen}
              />
            )}
          </MenuS>
        </DisplayFlexS>
      </HeaderContentS>
      <MobileSearchWrapperS isOpen={isSearchInputOpen}>
        <SearchWrapperS>
          <SearchMobileInputS
            placeholder={i18n.t('header.inputPlaceholder')}
            value={searchInputMobile}
            onChange={(e) => {
              setSearchInputMobile(e.target.value);
              dispatch(makeSearchAction(e.target.value));
            }}
          />
          <EraseIcon src={closeCircle} alt="erase" onClick={() => setSearchInputMobile('')} />
          <SearchBtnS onClick={handleSearchTrustee}>{i18n.t('general.searchBtn')}</SearchBtnS>
        </SearchWrapperS>
        {!!searchInputMobile.length && (
          <div>
            {companies.slice(0, 3).map(({ name }) => (
              <CompanyTitleS
                key={name}
                onClick={() => {
                  setSearchInputMobile(name);
                }}
              >
                <span>{searchInputMobile}</span>
                <span>{name.slice(searchInputMobile.length)}</span>
              </CompanyTitleS>
            ))}
          </div>
        )}
      </MobileSearchWrapperS>
      <MobileNav isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </HeaderWrapperS>
  );
};

export default Header;
