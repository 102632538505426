import { appName } from '../../config';

export const moduleName = 'app';
const prefix = `${appName}/${moduleName}`;

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const NOTIFICATION_DISAPPEAR_TIME = 2000;

// Const
export const TOGGLE_AUTH_MODAL = `${prefix}/TOGGLE_AUTH_MODAL`;

export const SHOW_NOTIFICATION_INIT = `${prefix}/SHOW_NOTIFICATION_INIT`;
export const SHOW_NOTIFICATION = `${prefix}/SHOW_NOTIFICATION`;
export const HIDE_NOTIFICATION = `${prefix}/HIDE_NOTIFICATION`;

export const CREATE_CONTACTS_MESSAGE = `${prefix}/CREATE_CONTACTS_MESSAGE`;

// send write us
export const CREATE_WRITE_US_MESSAGE = `${prefix}/CREATE_WRITE_US_MESSAGE`;

export const GET_APP_STATISTIC_REQUEST = `${prefix}/GET_APP_STATISTIC_REQUEST`;
export const GET_APP_STATISTIC_SUCCESS = `${prefix}/GET_APP_STATISTIC_SUCCESS`;

export const GET_ACTIVE_FILTERS_REQUEST = `${prefix}/GET_ACTIVE_FILTERS_REQUEST`;
export const GET_ACTIVE_FILTERS_SUCCESS = `${prefix}/GET_ACTIVE_FILTERS_SUCCESS`;

export const GET_GRAPH_ACTIVE_FILTERS_REQUEST = `${prefix}/GET_GRAPH_ACTIVE_FILTERS_REQUEST`;
export const GET_GRAPH_ACTIVE_FILTERS_SUCCESS = `${prefix}/GET_GRAPH_ACTIVE_FILTERS_SUCCESS`;

export const GET_STATIC_COMPANY_DATA_REQUEST = `${prefix}/GET_STATIC_COMPANY_DATA_REQUEST`;
export const GET_STATIC_COMPANY_DATA_SUCCESS = `${prefix}/GET_STATIC_COMPANY_DATA_SUCCESS`;
