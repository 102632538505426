import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { Text } from './Text';
import { getThemeColor, getThemeSize } from '../../../utils/theme';
import ArrowIcon from "../../assets/imgs/icons/Arrow.svg";

const IconWrapS = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px;
    min-width: ${props => props.sizeWidth || "27px"};
    height: 32px;
    border-radius: 3px;
    background: ${({bgColor}) => getThemeColor([bgColor])};
    transition: all 0.3s ease-in-out;
`;

const IconArrowWrapS = styled(IconWrapS)`
    border: 1px solid;
    border-color: ${({bgColorHover}) => getThemeColor([bgColorHover])};
`;

const IconS = styled.img`;
    height: ${({size}) => getThemeSize([size || '14px'])};
    transition: all 0.3s ease-in-out;
`;

const IconNumberS = styled(Text)`
    color: ${({color}) => getThemeColor([color || 'primary'])};
`;

const IconBtnWrapS = styled.button`
    display: flex;
    align-items: center;
    background: none;
    padding: 0px 5px;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    color: ${({color}) => getThemeColor([color || 'white'])};
    margin: ${R.propOr('', 'margin')};

    ${({active}) => {return !active}},
    &:hover
     {
        ${IconNumberS} {
            color: ${({colorActive}) => getThemeColor([colorActive])};
        }
        ${IconWrapS} {
            box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            background-color: ${({bgColorActive}) => getThemeColor([bgColorActive])};
        }
    }
    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    };
    &:hover
     {
        ${IconNumberS} {
            color: ${({colorHover}) => getThemeColor([colorHover])};
        }
        ${IconWrapS} {
            box-shadow: none;
            filter: none;
            background-color: ${({bgColorHover}) => getThemeColor([bgColorHover])};
        }
    };
`;

/*
* Component rendering Icon, Icon + text, number
* typeArrow - prev and next buttons
* number - display the number instead of the icon
* disabled - disabled element
* colorActive - color for actine button
* colorHover - hover color
* bgColorActive - background for actine button
* bgColorHover - hover backgroundColor for Icon
* */

export const IconBtnSCustomer = ({
  onClick,
  disabled,
  typeArrow,
  number,
  color,
  colorHover,
  colorActive,
  bgColorHover,
  bgColorActive,
  sizeWidth,
  margin,
  active,
  rotate,
  className
}) => (
  <IconBtnWrapS {...{ disabled, bgColorHover, bgColorActive, color, colorHover, colorActive, margin, active, onClick }} className={className || ''}>
      {
        typeArrow
          ? 
          <IconArrowWrapS {...{ sizeWidth, bgColorHover }}>
            <IconS size="normalText" src={ArrowIcon} style={rotate ? {transform: 'rotate(180deg)'} : null}/>
          </IconArrowWrapS>
          : 
          <IconWrapS {...{ sizeWidth }}>
            <IconNumberS size="normalText">{number}</IconNumberS>
          </IconWrapS>
      }
  </IconBtnWrapS>
);