export default {
    colors: {
      /* delete firts part when all will be changed to new colors */
     primary: '#4d4d4d', // its from doc dark-gray
     primaryGray: 'rgba(51, 51, 51, 0.349019607843137)',
     gray_1: '#C4C4C4',
     primaryButton: 'rgba(102, 102, 102, 1)',
     white: '#fff',
     black: '#000',

     // under colors it the final, all above remove when all view will be ready
     mainBackgroundColor: 'rgba(246, 249, 252, .5)',

     blue: '#709dc7',
     lightBlue: '#E7F3FF',
     darkBlue: '#507eb1',
     grayBlue: '#dde9f5',
     greyBlueLight: '#eaf2f9',
     greyBlueLightAlpha: 'rgba(29, 29, 108, 0.15)',
     greyBlueDarkAlpha: 'rgba(29, 29, 108, 0.75)',
     beige: '#FFE9A6', 

     red: '#d90e4b',

     violet: '#1d1d6c',
     lighterViolet: '#1d1d8c',
     darkViolet: '#424770',
     darkerViolet: '#32325d',
     brightViolet: '#6772e5',
     brightVioletLight: '#D8D8F4',

     greyLight: '#f6f9fc',
     greyViolet: '#525f7f',
     greyVioletLight: '#8898aa',

     greyBackground: '#EBEDEF',
     greyDark: '#c4c4c4',
     greyHistory: '#bdbdbd',
     lightViolet: '#6772E5',
     greyYellow: '#EAAB45',
     green: '#3ECF8E',
     checkVerification: '#74dbf9',

     shamRockAlpha: 'rgba(64, 207, 143, 0.2)',
     tulipTreeAlpha: 'rgba(234, 171, 69, 0.2)',
     redAlpha: 'rgba(217, 14, 75, 0.1)',
     cornflowerBlueAlpha:  'rgba(103, 114, 229, 0.2)',
     whiteAlpha: 'rgba(255, 255, 255, 0.75)',
     greyRed: '#C01246',
     greenAlpha: 'rgba(64, 207, 143, 0.2)',

     greyButton: '#E3E2E2',
     greyIcon: '#6B6B6B'

    },
    fonts: {
      regular: 'Lato-Regular',
      bold: 'Lato-Bold'
    },
    sizes: {
      largeText: '20px',
      middleText: '18px',
      bigText: '16px',
      normalText: '14px',
      mediumText: '13px',
      smallText: '12px',
      middleSmallText: '11px',
      extraSmallText: '10px',

      headers: {
        main: '48px',
        secondary: '30px',
        secondaryMobile: '25px',
        companyItemName: '20px',
        authModal: '24px',
        authModalMobile: '28px',
      },

      tagSize: '14px',

      input: '14px',

      buttonText: '16px',
    },

    screens: {
      medium: '768px',
      tabletPC: '1200px',
    }
  }

