import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdminLayout from '../components/adminPanel';
import { AppWrapperS, ContentS, Footer, Header } from '../components/layout';
import { NotificationBar } from '../components/common';

import { routes, routesComponents } from './routesConfig';

export default () => (
  <Switch>
    <Route path={`${routes.ADMIN.BASE}${routes.ADMIN.LOGIN}`} component={routesComponents.ADMIN.LOGIN} />

    <Route path={routes.ADMIN.BASE} render={({ match: { url } }) => (
        <AdminLayout>
          <Switch>
            <Route path={`${url}${routes.ADMIN.PROFILE}`} component={routesComponents.ADMIN.PROFILE} />
            <Route path={`${url}${routes.ADMIN.COMPANIES}`} component={routesComponents.ADMIN.COMPANIES} />
            <Route exact path={`${url}${routes.ADMIN.COMMENTS}`} component={routesComponents.ADMIN.COMMENTS} />
            <Route path={`${url}${routes.ADMIN.STATUSES}`} component={routesComponents.ADMIN.STATUSES} />
            <Route path={`${url}${routes.ADMIN.COMPANY_PROFILE}`} component={routesComponents.ADMIN.COMPANY_PROFILE} /> {/* private route TODO: make auth function work from here.*/}
            <Route path={`${url}${routes.ADMIN.COMPANY_INITIAL}`} component={routesComponents.ADMIN.COMPANY_INITIAL} />
            <Route path={`${url}${routes.ADMIN.NEWSLETTER}`} render={() => <div>newsletter</div>} />
            <Route path={`${url}`} component={routesComponents.ADMIN.DASHBOARD} />
            <Redirect to='/404' />
          </Switch>
        </AdminLayout>
      )}
    />
    <Route path="/" render={({ match: { url } }) => (
      <AppWrapperS>
        <Header />
        <ContentS>
          <Switch>
            <Route exact path={routes.HOME} component={routesComponents.HOME} />

            <Route exact path={routes.COMPANIES} component={routesComponents.COMPANIES} />

            <Route exact path={routes.COMPANY} component={routesComponents.COMPANY} />

            <Route exact path={routes.COMPANY_EDIT} component={routesComponents.COMPANY_EDIT} />

            <Route exact path={routes.REVIEW_CREATE} component={routesComponents.REVIEW_CREATE} />

            <Route exact path={routes.PROFILE} component={routesComponents.PROFILE} />

            <Route exact path={routes.FOR_COMPANIES} component={routesComponents.FOR_COMPANIES} />

            <Route exact path={routes.CONTACTS} component={routesComponents.CONTACTS} />

            <Route exact path={routes.AGB} component={routesComponents.AGB} />

            <Route exact path={routes.DATA_PROTECTION} component={routesComponents.DATA_PROTECTION} />

            <Route component={routesComponents.NOT_FOUND} />
          </Switch>
        </ContentS>
        <Footer />
        <NotificationBar />
      </AppWrapperS>
    )} />
  </Switch>
);
