import styled from 'styled-components';

import {DisplayFlexS} from '../../../../utils/stylesHelpers';
import {getThemeColor, getThemeSize} from '../../../../utils/theme';
import {LightBtnS} from '../../common/Button';

export const TotalPages = styled.div`
  color: ${getThemeColor(['primary'])};
  font-size: ${getThemeSize(['bigText'])};
`;

export const TotalPagesCount = styled.span`
  color: ${getThemeColor(['red'])};
  font-size: ${getThemeSize(['bigText'])};
  margin-left: 10px;
`;

export const NavWrapS = styled(DisplayFlexS)`
  margin-top: 50px;
`;

export const NavTabs = styled(DisplayFlexS)`
  margin-bottom: 20px;
`;

export const NavTabsBtn = styled(LightBtnS)`
  margin-right: 20px;
  ${({active}) => active && `
    color: #fff;
    background-color: #d90e4b;
  `};
`;

