import i18next from 'i18next';
import * as R from 'ramda'
import React from 'react';
import styled from 'styled-components';

import { getScreenSize } from "../../../../../utils/theme";
import { FieldError, PrimaryButtonS, TextInput } from '../../../common';


const Form = styled.form`
  width: 92%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${getScreenSize(['medium'])}) {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  min-height: 70px;
  margin-bottom: 20px;
  
  padding-bottom: 20px;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin-bottom: 0;
  }
`;


export const EmailFormComponent = ({
  values,
  errors,
  touched,
  onSubmit,
  onChange,
  onBlur,
  isSubmitting,
  serverError
}) => (
  <Form {...{ onSubmit }}>
    <FieldError error={serverError && i18next.t(`general.serverErrorsByCodes.${serverError.messageCode}`)} />
    <InputWrapper >
      <TextInput
        name="email"
        type="text"
        placeholder={i18next.t('forms.common.emailForm.placeholder')}
        {...{ onChange, onBlur }}
        value={R.prop('email', values)}
        isError={R.prop('email', errors) && R.prop('email', touched)}
        error={errors.email}
      />
    </InputWrapper >

    <PrimaryButtonS
      disabled={isSubmitting}
      type="submit"
    >
      {i18next.t('forms.common.emailForm.submitBtn')}
    </PrimaryButtonS>
  </Form>
);
