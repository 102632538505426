import styled from 'styled-components';
import { propOr } from 'ramda';

import { getThemeColor } from '../../../utils/theme';

export default styled.div`
  width: ${propOr('100%', 'width')};
  height: ${propOr('1px', 'height')};
  border-top: ${propOr('1px', 'height')} solid ${getThemeColor(['grayBlue'])};
  display: block;
`;
