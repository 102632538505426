import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { path } from 'ramda';

import { routes } from "../../../../routes/routesConfig";
import { Text, Icon } from '../../../common/';
import IconAdmin from '../../../../assets/imgs/icons/Admin_Icon.png';

import {
  SidebarAvatarS,
  SidebarItemIconS,
  SidebarListItemS,
  SidebarListLinkS,
  SidebarListS,
  SidebarProfileS,
  SidebarSubListLinkS,
  SidebarSubListS,
  SidebarWrapS
} from './SidebarS';

const SIDEBAR_MENU_LINKS = [
  {
    to: `${routes.ADMIN.BASE}`,
    text: t('adminPanel.sidebar.home')
  },
  {
    to: `${routes.ADMIN.BASE}${routes.ADMIN.COMPANIES}`,
    text: t('adminPanel.sidebar.company'),
    subNav: [
      { to: `${routes.ADMIN.BASE}${routes.ADMIN.COMPANIES}`, text: t('adminPanel.sidebar.profile') },
      { to: `${routes.ADMIN.BASE}${routes.ADMIN.STATUSES}`, text: t('adminPanel.sidebar.status'), countComments: 2 },
      { to: `${routes.ADMIN.BASE}${routes.ADMIN.COMMENTS}`, text: t('adminPanel.sidebar.comments'), counterProp: 'unhandledCommentsCount' }
    ]
  },
  {
    to: `${routes.ADMIN.BASE}${routes.ADMIN.NEWSLETTER}`,
    text: t('adminPanel.sidebar.newsletter')
  }
];

const SidebarComponent = ({ ...props }) => (
  /* TODO: name admin will later be changed from Redux */
  <SidebarWrapS>
    <SidebarProfileS
      to={`${routes.ADMIN.BASE}${routes.ADMIN.PROFILE}`}
    >
      <SidebarAvatarS>
        <Icon src={IconAdmin} alt="admin-icon" size="35"/>
      </SidebarAvatarS>
      <Text size="largeText" fontFamily="bold" color="white">{path(['admin', 'name'], props)}</Text>
    </SidebarProfileS>
    <SidebarListS>
      {
        SIDEBAR_MENU_LINKS.map((link, i) => {
          const doesRouteMatchSubLinks = link.subNav && [link.to, ...link.subNav.map(el => el.to)].includes(props.location.pathname);

          return (
            <SidebarListItemS key={i}>
              <SidebarListLinkS
                to={link.to}
                exact
                className={doesRouteMatchSubLinks ? 'active' : ''}
              >
                <Text size="largeText"
                      color="whiteAlpha"
                      transform="uppercase"
                      margin="0 80px 0 0"
                >
                  {link.text}
                </Text>
                {link.subNav &&
                  <SidebarItemIconS
                    icon="angle-down"
                    size="2x"
                    open={doesRouteMatchSubLinks}
                  />
                }
              </SidebarListLinkS>
              {link.subNav &&
                <SidebarSubListS
                  isActive={doesRouteMatchSubLinks}
                >
                {
                  link.subNav.map((subLink, i) => (
                    <SidebarSubListLinkS to={subLink.to} key={i}>
                      <Text size="largeText"
                            color="whiteAlpha"
                      >
                        {subLink.text}
                        {props[subLink.counterProp] > 0 && ` (${props[subLink.counterProp]})`}
                      </Text>
                    </SidebarSubListLinkS>
                  ))
                }
                </SidebarSubListS>
              }
            </SidebarListItemS>
          )
        })
      }
    </SidebarListS>
  </SidebarWrapS>
);

export default connect(({ router, review, auth }) => ({
  location: router.location,
  admin: auth.activeUser,
  unhandledCommentsCount: review.unhandledAnswersCount + review.unhandledCommentsCount + review.unhandledRatingsCount
}))(SidebarComponent);
