import GoogleAnalytics from 'react-ga';

export const modalGA = (path) => {
  GoogleAnalytics.modalview(path)
};

/**
 *  Create event for Google Analytics
 *  @param eventGAData = { category, action, value, label }
 * */
export const eventGA = (eventGAData) => {
  GoogleAnalytics.event(eventGAData);
};

/**
 *  Create social link for Google Analytics
 *  @param name: string
 *  @param link: string
 * */
export const socialLinkGA = (name, link) => {
  GoogleAnalytics.ga('send', 'social', {
    'socialNetwork': 'Links',
    'socialAction': name,
    'socialTarget': link
  })
};
