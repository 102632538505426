/*
*  Write logic for carousel
*  1. lifecicle : componentDidMount - call api get items, componentDidUpdate = fetch more items
*  2. state for changing page of items (starts from 0)
*  3. sync it with redux store (for now it in local state, change if we will need save page of carousel )
* */
import CarouselComponent from './carouselComponent';

import { equals, prop, inc, dec, multiply, gt, find } from 'ramda';
import { compose, withState, lifecycle, withHandlers, withProps } from 'recompose';

/**
 *  @param
 *  fetchData {Func} - should accept
 * */
const CarouselContainer = compose(
  withState('numOfPage', 'setNumOfPage', 0),
  withProps({ itemsPerView: 4 }),
  lifecycle({
    componentDidMount() {
      const { fetchCarouselEntities, numOfPage, itemsPerView } = this.props;

      fetchCarouselEntities({ skip: multiply(numOfPage, itemsPerView), limit: itemsPerView }); // call api
    },
    componentDidUpdate(prevProps) {
      const { fetchCarouselEntities, numOfPage, itemsPerView, items } = this.props;

      const isPropNotEqual = i => !equals(prop(i, this.props), prop(i, prevProps));

      const isPiesAlreadyLoaded = () => items.length > itemsPerView * dec(numOfPage) + itemsPerView;

      if (find(isPropNotEqual)(['numOfPage']) && gt(numOfPage, prevProps.numOfPage) && !isPiesAlreadyLoaded()) {
        fetchCarouselEntities({ skip: multiply(numOfPage, itemsPerView), limit: itemsPerView });
      }
    },
  }),
  withHandlers({
    switchCarousel: ({ setNumOfPage, numOfPage }) => (goBack) => { // by default move forward
      setNumOfPage(goBack ? dec(numOfPage) : inc(numOfPage));
    }
  })
);

export default CarouselContainer(CarouselComponent);
