import React from 'react';
import styled from 'styled-components';

const LinkS = styled.a`
  text-decoration: none;
  
   &:hover {
    cursor: pointer;
    text-decoration: none;
  };
`;

/*
* Currently it doesn't support handling onClick event just redirecting
* */
const LinkTag = ({ content, href, target = '_blank', ...rest }) => <LinkS { ...{ target, href, ...rest } } >{content}</LinkS>;

const MailLink = ({ email, content }) => <LinkS href={`mailto:${email}`}>{content ? content : email}</LinkS>;

export { LinkTag, MailLink };
