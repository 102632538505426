import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { ROLES } from '../../app/constants/user';

import { getItem } from '../helpers/localStorage';
import { isAdmin } from '../helpers/applicationHelpers';

export const isAdminTokenPresent = () => !!getItem('adminToken');
export const isUserTokenPresent = () => !!getItem('token');

export const isLoggedIn = () => isAdmin() ? isAdminTokenPresent() : isUserTokenPresent();

export const getAuthHeader = () => `Bearer ${isAdmin() ? getItem('adminToken') : getItem('token')}`;

/**
 * HOF for checking has user access to route or not (this is for client only no in admin.)
 *
 * @param  component
 *
 * @returns Component { React Component }
 */
export const auth = component => compose(
  connect(state => ({
    isLoggedIn: !!state.auth.token,
  })),
  lifecycle({
    componentDidMount() {
      const { isLoggedIn, history } = this.props;

      if (!isLoggedIn && !getItem('token') && !isAdmin) {
        history.push('/');
      }
    },
  })
)(component);

export const hasAccessToAdminPanel = activeUser => activeUser && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(activeUser.role);
