export default `

  .sm-display-none {
    @media only screen and (max-width: 768px) {
        display: none;
    }
  }
  .sm-display-flex {
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
    }
  }
`;