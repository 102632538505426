import {
  GET_ACTIVE_FILTERS_SUCCESS,
  GET_GRAPH_ACTIVE_FILTERS_SUCCESS,
  GET_APP_STATISTIC_SUCCESS,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
  TOGGLE_AUTH_MODAL,
  GET_STATIC_COMPANY_DATA_SUCCESS
} from './appConstants';
import { filterNames } from '../../app/constants/filters';

const initialState = {
  statistic: {
    companies: 0,
    services: 0,
  },
  activeFilters: {
    [filterNames.KANTONES]: [],
    [filterNames.CITY]: [],
    [filterNames.LOCATIONS]: [],
    [filterNames.SOFTWARE]: [],
    [filterNames.COMMUNICATION_PREFERENCES]: [],
    [filterNames.BASIS]: [],
    [filterNames.SERVICES]: [],
    [filterNames.INDUSTRIES]: [],
    [filterNames.SPECIAL_OFFER]: [],
    [filterNames.LANGUAGES]: [],
    [filterNames.MEMBER]: [],
    [filterNames.HOURLY_RATE]: [],
    [filterNames.TAX_REPRESENTATION]: [],
  },
  staticCompanyValues: {
    communicationPreferences: [],
    industries: [],
    services: {
      specialisation: []
    },
    languages: [],
    members: [],
    softwares: []
  },
  authModal: { // TODO: move it later for modals module
    open: false,
    layout: null,
  },
  notificationBar: {
    messages: [] // { text, msgType, id }
  }
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_AUTH_MODAL: {
      return {
        ...state,
        authModal: {
          ...state.authModal,
          ...payload,
        }
      }
    }
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notificationBar: {
          messages: [ ...state.notificationBar.messages, payload ],
        }
      }
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notificationBar: {
          messages: state.notificationBar.messages.filter(i => i.id !== payload) // payload is id
        }
      }
    }
    case GET_APP_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistic: {
          companies: payload.companiesCount,
          services: payload.servicesCount,
        }
      }
    }
    case GET_ACTIVE_FILTERS_SUCCESS: {
     return {
      ...state,
      activeFilters: {
        ...state.activeFilters,
        ...payload
      }
     }
    }
    case GET_GRAPH_ACTIVE_FILTERS_SUCCESS: {
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          rate: payload.data
        }
      }
    }
    case GET_STATIC_COMPANY_DATA_SUCCESS: {
      return {
        ...state,
        staticCompanyValues: payload
      }
    }
    default:
      return state;
  }

}
