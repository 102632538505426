import {
  CREATE_COMPANY_REVIEW_REQUEST,
  CREATE_OWNER_ANSWER_REQUEST,
  FETCH_COMPANY_REVIEWS_REQUEST,
  FETCH_UNVERIFIED_COMMENTS_REQUEST,
  FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST,
  TOGGLE_REVIEW_ALERT,
  UPDATE_COMPANY_RATING_REQUEST,
  POST_COMMENT_BY_ADMIN_REQUEST,
  EDIT_COMMENT_BY_ADMIN_REQUEST,
  DELETE_COMMENT_BY_ADMIN_REQUEST,
  FETCH_UNVERIFIED_RATINGS_REQUEST,
  DELETE_RATING_BY_ADMIN_REQUEST,
  POST_RATING_BY_ADMIN_REQUEST
} from './reviewConstants';

export const fetchCompanyReviews = payload  => ({
  type: FETCH_COMPANY_REVIEWS_REQUEST,
  payload, // companyId
});

export const createCompanyReview = payload => ({
  type: CREATE_COMPANY_REVIEW_REQUEST,
  payload, // { text, belongTo, createdBy }
});

export const createOwnerAnswer = payload => ({
  type: CREATE_OWNER_ANSWER_REQUEST,
  payload, // { companyAnswer }
});

export const updateCompanyRating = payload => ({
  type: UPDATE_COMPANY_RATING_REQUEST,
  payload // { score, belongTo, createdBy: { name, email } }
});

export const toggleReviewAlert = payload => ({
  type: TOGGLE_REVIEW_ALERT,
  payload
});

export const fetchUnhandledCommentsCounts = () => ({
  type: FETCH_UNHANDLED_COMMENTS_COUNTS_REQUEST
});

export const fetchUnverifiedCommentsList = payload => ({
  type: FETCH_UNVERIFIED_COMMENTS_REQUEST,
  payload
});

export const fetchUnverifiedRatingsList = payload => ({
  type: FETCH_UNVERIFIED_RATINGS_REQUEST,
  payload
});

export const postCommentByAdmin = commentId => ({
  type: POST_COMMENT_BY_ADMIN_REQUEST,
  payload: { commentId }
});

export const editCommentByAdmin = (commentId, text) => ({
  type: EDIT_COMMENT_BY_ADMIN_REQUEST,
  payload: { commentId, text }
});

export const deleteCommentByAdmin = commentId => ({
  type: DELETE_COMMENT_BY_ADMIN_REQUEST,
  payload: { commentId }
});

export const postRatingByAdmin = ratingId => ({
  type: POST_RATING_BY_ADMIN_REQUEST,
  payload: { ratingId }
});

export const deleteRatingByAdmin = ratingId => ({
  type: DELETE_RATING_BY_ADMIN_REQUEST,
  payload: { ratingId }
});

