/*
*  Use this for reset pass, resolve conflicts for registration
*
*  props:
*  submitHandler
*   serverError - {Object}
* */

import { EmailFormContainer } from './emailFormContainer';

export default EmailFormContainer;