import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getScreenSize, getThemeColor, getThemeSize } from '../../../utils/theme';
import { DisplayFlexS } from '../../../utils/stylesHelpers';
import { Text } from '../common';
import { setItem } from '../../../utils/helpers/localStorage';
import changeLanguageForDate from '../../../utils/helpers/changeLanguageForDate';

const LanguageSwitcherWrap = styled.div`
  margin: 0 25px;
  border-radius: 3px;
  background-color: ${getThemeColor(['greyLight'])};
  cursor: pointer;

  @media (max-width: ${getScreenSize(['medium'])}) {
    margin: 10px 0 0;
    padding: 5px;
  }
`;

const LanguageHeader = styled(DisplayFlexS)`
  padding: 0 10px 0 15px;
  color: ${getThemeColor(['violet'])};
  font-size: ${getThemeSize(['bigText'])};
`;

const DropdownLanguageSwitcherS = styled.div`
  display: block;
  position: absolute;
  top: 55px;
  min-width: 110px;
  margin: 0 auto;
  padding: 20px 0 10px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);

  @media (max-width: ${getScreenSize(['medium'])}) {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto 0 0;
    padding: 0;
    box-shadow: none;
  }
`;

const LanguageItems = styled(DisplayFlexS)`
  padding: 5px 15px;
  color: ${getThemeColor(['primary'])};
  font-size: ${getThemeSize(['normalText'])};

  &:hover {
    color: ${getThemeColor(['white'])};
    background-color: ${getThemeColor(['violet'])};
  }
`;

const LANGUAGES = [
  { name: 'Deutsch', key: 'de' },
  { name: 'English', key: 'en' },
];

export const handleLanguageSwitch = (lan) => {
  const isLanguagePassed = LANGUAGES.some((language) => language.key === lan);
  const currentLanguage = i18n.language;
  const newLanguage = isLanguagePassed ? lan : currentLanguage === 'de' ? 'en' : 'de';

  i18n.changeLanguage(newLanguage);
  setItem('i18nLanguage', newLanguage);
  changeLanguageForDate();
  document.location.reload(true);
};

const LanguageSwitcher = () => {
  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    if (showItems) {
      const handleClickOutside = () => setShowItems(false);
      document.addEventListener('click', handleClickOutside, { once: true });
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [showItems]);

  return (
    <LanguageSwitcherWrap onClick={() => setShowItems(!showItems)}>
      <LanguageHeader aItems="center" alignItemsScreenMedium="center">
        <Text fontFamily="bold" size="bigText" color="violet" style={{ marginRight: 10 }}>
          {i18n.language.toUpperCase()}
        </Text>
        <FontAwesomeIcon size="2x" icon="angle-down" />
      </LanguageHeader>
      {showItems && (
        <DropdownLanguageSwitcherS>
          {LANGUAGES.map((lang) => (
            <LanguageItems key={lang.key} onClick={() => i18n.language !== lang.key && handleLanguageSwitch(lang.key)}>
              {lang.name}
            </LanguageItems>
          ))}
        </DropdownLanguageSwitcherS>
      )}
    </LanguageSwitcherWrap>
  );
};

export default LanguageSwitcher;
