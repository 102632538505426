import { withFormik } from 'formik';
import { compose, mapProps } from 'recompose';

export const withForm = props => compose(
  withFormik({

    // TODO: move here common logic after forms implementation
    ...props,
    enableReinitialize: true,
  }),
  mapProps(({ handleChange, handleSubmit, handleBlur, ...rest }) => ({
    onSubmit: handleSubmit,
    onChange: handleChange,
    onBlur: handleBlur,
    ...rest,
  })),
);

/**
 * formatFieldsError :: Object -> String
 */
//export const formatFieldsError = ({ errors, touched, fields }) =>
//  R.compose(
//    R.join(', '),
//    R.reduce((acc, key) => touched[key] ? acc.concat(errors[key]) : acc, []),
//    R.keys,
//    R.pick(fields),
//  )(errors);
