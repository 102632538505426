import styled from 'styled-components';

import { getScreenSize } from '../../utils/theme';

/*
 * Reusable wrapper in order to not use div
 * */
export const DisplayFlexS = styled.div`
  width: ${({ width = '100%' }) => width};
  min-width: ${({ minWidth = 'auto' }) => minWidth};
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  margin: ${({ margin = 0 }) => margin};

  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  flex-basis: ${({ basis = 'auto' }) => basis};
  align-items: ${({ aItems = 'flex-start' }) => aItems};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};

  @media (max-width: ${getScreenSize(['tabletPC'])}) {
    flex-wrap: ${({ flexWrapScreenTablet = 'nowrap' }) => flexWrapScreenTablet};
    max-width: ${({ maxWidthScreenTablet = '100%' }) => maxWidthScreenTablet};
  }

  @media (max-width: ${getScreenSize(['medium'])}) {
    flex-direction: ${({ directionScreenMedium = 'row' }) => directionScreenMedium};
    justify-content: ${({ justifyScreenMedium = 'flex-start' }) => justifyScreenMedium};
    align-items: ${({ alignItemsScreenMedium = 'flex-start' }) => alignItemsScreenMedium};
    margin: ${({ marginScreenMedium = 0 }) => marginScreenMedium};
  }
`;
