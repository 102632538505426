import * as Sentry from '@sentry/browser'

const SENTRY_ENVIRONMENTS = {
  QA: 'qa',
  PROD: 'prod'
};

const ENVS = {
  QA: 'qa',
  PROD: 'prod',
  DEV: 'dev'
};

export const initSentry = () => {
  if([ENVS.PROD, ENVS.QA].includes(process.env.REACT_APP_ENV)) {
    const environment = ENVS.PROD === process.env.REACT_APP_ENV ? SENTRY_ENVIRONMENTS.PROD : SENTRY_ENVIRONMENTS.QA;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment
    });
  }
};