import { filterNames } from '../../../constants/filters';

export const SERVICES_NAMES = [
  {
    filterName: filterNames.KANTONES,
    nameTrans: 'companies.filter.cantonTitle',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.kanton',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.kantonNotFound'
    }
  },
  // Filter CITY on search result page
  // {
  //   filterName: filterNames.CITY,
  //   nameTrans: 'companies.filter.cityTitle',
  //   searchFieldData: {
  //     placeholder: 'companies.filter.searchPlaceholders.city',
  //     errorMessageOnNoFound: 'companies.filter.searchErrorMessages.cityNotFound'
  //   }
  // },
  {
    filterName: filterNames.ZIP,
    nameTrans: 'companies.filter.zipTitle',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.zip',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.zipNotFound'
    }
  },
  {
    filterName: filterNames.HOURLY_RATE,
    nameTrans: 'companies.filter.rateTitle',
    histogram: true
  },
  {
    filterName: filterNames.SOFTWARE,
    nameTrans: 'companies.filter.softwareTitle',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.software',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.softwareNotFound'
    }
  },
  {
    filterName: filterNames.COMMUNICATION_PREFERENCES,
    nameTrans: 'companies.filter.communicationPreferencesTitle',
    optionTranslatePath: 'communicationPreferences',
    isWithoutCounter: true,
  },
  {
    filterName: filterNames.BASIS,
    nameTrans: 'companies.filter.servicesBasisTitle',
    optionTranslatePath: 'companies.filter.servicesBasisTitle',
  },
  {
    filterName: filterNames.SERVICES,
    nameTrans: 'companies.filter.servicesTitle',
    optionTranslatePath: 'companyServices',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.service',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.serviceNotFound'
    }
  },
  {
    filterName: filterNames.INDUSTRIES,
    nameTrans: 'companies.filter.industriesTitle',
    optionTranslatePath: 'companyIndustries',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.industries',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.industriesNotFound'
    },
    isWithoutCounter: true,
  },
  {
    filterName: filterNames.SPECIAL_OFFER,
    nameTrans: 'companies.filter.specialOfferTitle',
    optionTranslatePath: 'companies.filter.specialOfferTitle',
  },
  {
    filterName: filterNames.LANGUAGES,
    nameTrans: 'companies.filter.languagesTitle',
    optionTranslatePath: 'companyLanguages',
    searchFieldData: {
      placeholder: 'companies.filter.searchPlaceholders.language',
      errorMessageOnNoFound: 'companies.filter.searchErrorMessages.languageNotFound'
    }
  },
  {
    filterName: filterNames.MEMBER,
    nameTrans: 'companies.filter.memberTitle',
  },
  {
    filterName: filterNames.TAX_REPRESENTATION,
    nameTrans: 'companies.filter.taxRepresentationTitle',
    optionTranslatePath: 'companies.filter.taxRepresentationTitle',
  }
];