import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import { uniq, omit, path } from 'ramda';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { companyFilterActions } from '../../../../modules/companyFilter';
import { eventGA } from '../../../../utils/GA';
import searchFn from '../../../../utils/helpers/searchFn';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';
import { filterNames } from '../../../constants/filters';
import { categoryGA } from '../../../constants/googleAnalytics';
import { Text, CheckBox } from '../../common';
import Histogram from '../../common/histogram';

import {
  ArrowIconWrap,
  FiltersWrapS,
  FilterWrapS,
  OptionsWrapS,
  StyledDivider,
  FilterOptionContainer,
  SearchInput,
  HistogramBtnS,
  SubmitFilterS,
} from './companiesFiltersS';
import { SERVICES_NAMES } from './constants';

const { setFilter, setZipFilter } = companyFilterActions;

const mapZip = ({ zip }) => zip;
const noAlleOption = (i) => !['Alle', 'All'].includes(i);

const isCheckedHelper = (filterName, option, filtersState) => {
  if ([filterNames.SPECIAL_OFFER, filterNames.BASIS, filterNames.TAX_REPRESENTATION].includes(filterName)) {
    return !!filtersState[filterName];
  }
  return filtersState[filterName].includes(option);
};

const translateOptionHelper = (filterName, optionTranslatePath, option) => {
  if ([filterNames.SPECIAL_OFFER, filterNames.BASIS, filterNames.TAX_REPRESENTATION].includes(filterName)) {
    return i18n.t(optionTranslatePath);
  }
  return i18n.t([`${optionTranslatePath}.${option}`, option]);
};

const FilterHeader = ({ nameTrans, filterName, toggleFilter, filtersViewState }) => (
  <DisplayFlexS justify="space-between" className="filter-header">
    <Text fontFamily="bold" color="violet" size="bigText">
      {i18n.t(nameTrans)}
    </Text>
    <ArrowIconWrap isOpen={filtersViewState[filterName]} onClick={() => toggleFilter(filterName)}>
      <FontAwesomeIcon size="2x" icon="angle-up" />
    </ArrowIconWrap>
  </DisplayFlexS>
);

const FilterOptions = ({ filterName, options, isChecked, onChange, optionTranslatePath, filtersState }) => (
  <OptionsWrapS>
    {options.map((option) => (
      <FilterOptionContainer key={option}>
        <label style={{ display: 'flex' }}>
          <CheckBox
            onChange={(e) => onChange(option, e.target.checked)}
            checked={isChecked(filterName, option, filtersState)}
          />
          <span style={{ lineHeight: '17px', marginTop: '-2px' }}>
            {translateOptionHelper(filterName, optionTranslatePath, option)}
          </span>
        </label>
      </FilterOptionContainer>
    ))}
  </OptionsWrapS>
);

const CompaniesFilters = ({ submitFiltersButton, onSubmitFilters }) => {
  const dispatch = useDispatch();
  const { filtersState, activeFilters } = useSelector((state) => ({
    filtersState: state.companyFilter.filter,
    activeFilters: state.app.activeFilters,
  }));

  const [localFiltersState, setLocalFiltersState] = useState(filtersState);

  const zipSearchRef = useRef(null);
  const [filtersViewState, setFiltersViewState] = useState({});
  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState({});
  const [hourlyRateData, setHourlyRateData] = useState([]);

  const getZipOptions = (hasChangedZipState = false) => {
    const searchStr = path(['current', 'value'], zipSearchRef);
    const isAllKantonesSelected =
      filtersState[filterNames.KANTONES].length === activeFilters[filterNames.KANTONES].length;

    const allZipsUniq = () =>
      isAllKantonesSelected || !filtersState[filterNames.KANTONES].length
        ? uniq(activeFilters[filterNames.LOCATIONS].map(mapZip))
        : uniq(
            activeFilters[filterNames.LOCATIONS]
              .filter(({ kanton }) => filtersState[filterNames.KANTONES].includes(kanton))
              .map(mapZip)
          );

    const getOptionsWithTwoStars = () => uniq(allZipsUniq().map((o) => `${o.slice(0, 2)}**`));

    if (searchStr) {
      if (searchStr.length === 1) {
        return getOptionsWithTwoStars();
      } else if (searchStr.length === 2) {
        return getOptionsWithTwoStars().includes(`${searchStr}**`)
          ? [`${searchStr}**`, ...allZipsUniq().filter((o) => new RegExp(`^${searchStr}`).test(o))]
          : allZipsUniq().filter((o) => new RegExp(`^${searchStr}`).test(o));
      }
      return hasChangedZipState ? options[filterNames.ZIP] : allZipsUniq();
    } else {
      const optionsWithStars = filtersState[filterNames.ZIP].filter((zip) => /\d\d[*][*]/.test(zip));
      return [
        ...filtersState[filterNames.ZIP],
        ...getOptionsWithTwoStars().filter((z) => !optionsWithStars.includes(z)),
      ];
    }
  };

  useEffect(() => {
    setOptions({
      ...omit([filterNames.LOCATIONS, filterNames.HOURLY_RATE], activeFilters),
      [filterNames.ZIP]: getZipOptions(),
    });

    if (activeFilters.rate) {
      setHourlyRateData(
        activeFilters.rate.map((rateItem) => ({
          ...rateItem,
          isActive: !!filtersState[filterNames.HOURLY_RATE][rateItem.name],
          selectedRange: filtersState[filterNames.HOURLY_RATE][rateItem.name] || [],
        }))
      );
    }
  }, [activeFilters, filtersState]);

  const toggleFilter = (filterName) => {
    setFiltersViewState((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));

    if (errors[filterName]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [filterName]: null,
      }));
    }
  };

  const doSearch =
    (filterName, optionTranslatePath, searchFiledData) =>
    ({ target: { value } }) => {
      let newOptions;

      if (filterName === filterNames.ZIP) {
        newOptions = searchFn(value, getZipOptions());
      } else {
        const translatedOptions = activeFilters[filterName].filter(noAlleOption).map((option) => ({
          value: option,
          label: i18n.t([`${optionTranslatePath}.${option}`, option]),
        }));

        newOptions = value
          ? searchFn(value, translatedOptions, 'label').map((e) => e.value)
          : activeFilters[filterName];
      }

      if (!newOptions.length) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [filterName]: i18n.t(searchFiledData.errorMessageOnNoFound),
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [filterName]: null,
        }));
      }

      setOptions((prevOptions) => ({
        ...prevOptions,
        [filterName]: newOptions,
      }));
    };

  const toggleButton = (name) => {
    setHourlyRateData((stateData) =>
      stateData.map((item) => ({
        ...item,
        isActive: item.name === name ? !item.isActive : false,
        selectedRange: [],
      }))
    );
  };

  const handleCheckBoxChange = (filterName, option, isChecked) => {
    const isSimpleCheckbox = [filterNames.SPECIAL_OFFER, filterNames.BASIS, filterNames.TAX_REPRESENTATION].includes(
      filterName
    );
    const value = isSimpleCheckbox ? isChecked : option;
    if (!submitFiltersButton) {
      if (filterName === filterNames.ZIP) {
        dispatch(setZipFilter(option));
      } else {
        dispatch(setFilter({ filterName, value }));
      }

      eventGA({
        category: categoryGA.FILTER,
        action: filterName,
        label: option,
      });
    } else {
      setLocalFiltersState((prev) => {
        let updatedFilter = prev[filterName];
        if (Array.isArray(updatedFilter) && filterName !== filterNames.ZIP) {
          if (!noAlleOption(option)) {
            if (prev[filterName].includes('Alle')) {
              updatedFilter = updatedFilter.filter((item) => item !== 'Alle');
            } else {
              updatedFilter = [...updatedFilter, 'Alle'];
            }
          }

          const allOptionsSelected = activeFilters[filterName].length === prev[filterName].length;
          const isAlleOption = !noAlleOption(option);

          if (allOptionsSelected) {
            if (isAlleOption) {
              updatedFilter = [];
            } else {
              updatedFilter = updatedFilter.filter((item) => item !== 'Alle' && item !== option);
            }
          } else {
            if (isAlleOption) {
              updatedFilter = activeFilters[filterName];
            } else {
              updatedFilter = isChecked ? [...updatedFilter, option] : updatedFilter.filter((item) => item !== option);
            }
          }
        } else {
          updatedFilter = value;
        }
        return {
          ...prev,
          [filterName === filterNames.ZIP ? 'zip' : filterName]: updatedFilter,
        };
      });
    }
  };

  const handleSubmitLocalFilters = () => {
    const changedFilters = {};

    Object.entries(localFiltersState).forEach(([filterName, filterValues]) => {
      const currentFilterValue = filtersState[filterName];

      if (Array.isArray(filterValues) && Array.isArray(currentFilterValue)) {
        if (
          filterValues.length !== currentFilterValue.length ||
          !filterValues.every((value, index) => value === currentFilterValue[index])
        ) {
          changedFilters[filterName] = filterValues;
        }
      } else if (filterValues !== currentFilterValue) {
        changedFilters[filterName] = filterValues;
      }
    });
    const trackEvent = (filterName, value) => {
      eventGA({
        category: categoryGA.FILTER,
        action: filterName,
        label: typeof value === 'boolean' ? value.toString() : value,
      });
    };

    Object.entries(changedFilters).forEach(([filterName, filterValues]) => {
      if (filterName === filterNames.ZIP) {
        dispatch(setZipFilter(filterValues));
      } else if (filterName === filterNames.RATE) {
        dispatch(setFilter({ filterName, value: filterValues }));
        trackEvent(filterName, filterNames.RATE);
      } else if (typeof filterValues === 'boolean') {
        dispatch(setFilter({ filterName, value: filterValues }));
        trackEvent(filterName, filterValues);
      } else if (Array.isArray(filterValues)) {
        const hasAlleOption = filterValues.some((value) => !noAlleOption(value));

        if (hasAlleOption) {
          const value = 'Alle';
          filterName === filterNames.ZIP ? dispatch(setZipFilter(value)) : dispatch(setFilter({ filterName, value }));
        } else {
          filterValues.forEach((value) => {
            filterName === filterNames.ZIP ? dispatch(setZipFilter(value)) : dispatch(setFilter({ filterName, value }));
            trackEvent(filterName, value);
          });
        }
      }
    });
  };

  const handleSetFilter = ({ filterName, value }) => {
    if (submitFiltersButton) {
      setLocalFiltersState((prev) => {
        const updatedRate = {
          ...prev.rate,
          ...value,
        };
        return { ...prev, rate: updatedRate };
      });
    } else {
      dispatch(setFilter({ filterName, value: { ...filtersState.rate, ...value } }));
    }
  };

  return (
    <div>
      <FiltersWrapS>
        {SERVICES_NAMES.map(({ nameTrans, filterName, optionTranslatePath, searchFieldData, histogram }) => (
          <FilterWrapS key={filterName}>
            <FilterHeader
              nameTrans={nameTrans}
              filterName={filterName}
              filtersState={submitFiltersButton ? localFiltersState : filtersState}
              filtersViewState={filtersViewState}
              toggleFilter={toggleFilter}
            />

            {filtersViewState[filterName] && (
              <>
                {searchFieldData && (
                  <SearchInput
                    placeholder={i18n.t(searchFieldData.placeholder)}
                    isError={!!errors[filterName]}
                    error={<span className="error-message">{errors[filterName]}</span>}
                    onChange={doSearch(filterName, optionTranslatePath, searchFieldData)}
                    onFocus={doSearch(filterName, optionTranslatePath, searchFieldData)}
                    ref={filterName === filterNames.ZIP ? zipSearchRef : null}
                  />
                )}

                {histogram && (
                  <>
                    <DisplayFlexS justify="center" wrap="wrap" margin="0 -10px 4px" className="histogram-btns-wrapper">
                      {hourlyRateData.map((item) => (
                        <HistogramBtnS
                          key={item.name}
                          fz="smallText"
                          onClick={() => toggleButton(item.name)}
                          isActive={item.isActive}
                        >
                          <Text color="violet" size="smallText">
                            {i18n.t([`companies.filter.rates.${item.name}`, [item.name]])}
                          </Text>
                        </HistogramBtnS>
                      ))}
                    </DisplayFlexS>
                    {hourlyRateData
                      .filter((item) => item.isActive)
                      .map((item) => (
                        <DisplayFlexS key={item.name} direction="column" className="histogram-wrapper">
                          <Histogram
                            homeFilter={false}
                            titleColor="primary"
                            dataItem={item.rates}
                            label={filterName}
                            filterName={item.name}
                            selectedRange={item.selectedRange}
                            setFilter={(filterName, value) => handleSetFilter(filterName, value)}
                          />
                        </DisplayFlexS>
                      ))}
                  </>
                )}

                <FilterOptions
                  filterName={filterName}
                  options={options[filterName] || []}
                  isChecked={isCheckedHelper}
                  onChange={(option, isChecked) => handleCheckBoxChange(filterName, option, isChecked)}
                  optionTranslatePath={optionTranslatePath}
                  filtersState={submitFiltersButton ? localFiltersState : filtersState}
                />
              </>
            )}

            <StyledDivider />
          </FilterWrapS>
        ))}
      </FiltersWrapS>
      {submitFiltersButton && (
        <SubmitFilterS
          onClick={(e) => {
            e.preventDefault();
            handleSubmitLocalFilters();
            onSubmitFilters();
          }}
        >
          {i18n.t('companies.filter.drawerFilterSubmitBtn')}
        </SubmitFilterS>
      )}
    </div>
  );
};

export default CompaniesFilters;
