import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { mailUrl } from "../../../constants/general.js";

import { CloseIconS, StyledModal } from '../modalCommonS';
import { PrimaryButtonS, GreyBtnS, Text } from '../../common';
import { getScreenSize, getThemeColor, getThemeFont, getThemeSize } from '../../../../utils/theme';
import { DisplayFlexS } from '../../../../utils/stylesHelpers';

const ResetPassWrapperS = styled(StyledModal)`
  justify-content: center; 
  padding: 0;
  width: 37rem;
`;

const HeaderS = styled(Text)`
  margin: 25px auto 5px;
  font-size: ${getThemeSize(['headers', 'authModal'])}; 
  color: ${getThemeColor(['violet'])};
  font-family: ${getThemeFont(['bold'])};
`;



const FormContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
`;

const FormResetPass = styled.form`
  width: 100%;
  padding: 35px;
  
  @media (max-width: ${getScreenSize(['medium'])}) {
     padding: 30px 20px;
  }
`;


export const ResetPassComponent = ({
  isModalOpen,
  toggleModal,
  onSubmit,
  company,
  auth
}) => (
  <ResetPassWrapperS
    isOpen={isModalOpen}
    onBackgroundClick={() => toggleModal(false)}
    onEscapeKeydown={() => toggleModal(false)}
  >
    <HeaderS>
      <Trans i18nKey="modals.resetPass.header" />
    </HeaderS>
      <FormResetPass {...{ onSubmit }} >
        <DisplayFlexS directionScreenMedium='column' direction='column' width='100%'>

        <FormContent>
          <Text color="gray" style={{ fontSize: '14px', fontWeight: 'mormal', margin: '0px 5px 10px 0px' }}>
            <Trans i18nKey='modals.resetPass.form.newPass.label.start'/>
            <Text>{company && company.companyItem && company.companyItem.name}</Text>
            <Trans i18nKey='modals.resetPass.form.newPass.label.end'/>
            <Text color="violet" style={{ fontSize: '14px', fontWeight: 'mormal' }}>{auth.generatedPass}</Text>
          </Text>
          
          <Text>
            <Trans i18nKey='modals.resetPass.form.sendMail.start' color="gray"/>
            <Text color="violet" style={{ margin: '0px 5px' }}>{mailUrl}</Text>
            <Trans i18nKey='modals.resetPass.form.sendMail.end' color="gray"/>
          </Text>
        </FormContent>

          {
              <DisplayFlexS justify="space-between">
                <GreyBtnS
                  type="button"
                  onClick={() => toggleModal(false)}
                >
                  <Trans i18nKey='modals.resetPass.cancelBtn'/>
                </GreyBtnS>

                <PrimaryButtonS type='submit'>
                  <Trans i18nKey='modals.resetPass.submitBtn'/>
                </PrimaryButtonS>
              </DisplayFlexS>
          }

        </DisplayFlexS>
      </FormResetPass>
    <CloseIconS onClick={() => toggleModal(false)} icon='times'/>
  </ResetPassWrapperS>
);


